const nextPMFilterOptions = () => {
  return [
    { text: 'Overdue (100%+) - PM', filterKey: 'Overdue', value: 'overdue' },
    {
      text: 'Due Soon (80 - 99%) - PM',
      filterKey: 'Due soon',
      value: 'dueSoon',
    },
    /*{ text: 'Upcoming (70 - 79%)', filterKey: 'Upcoming', value: 'upcoming' },*/
    {
      text: 'On Track (0 - 79%) - PM',
      filterKey: 'On Track',
      value: 'onTrack',
    },
    {
      text: 'Setup PM',
      filterKey: 'Setup PM',
      value: 'setupPm',
    },
  ];
};

const safetyInspectionsFilterOptions = () => {
  return [
    {
      text: 'Overdue (100%+) - Inspection',
      filterKey: 'Overdue',
      value: 'overdue',
    },
    {
      text: 'Due Soon (80 - 99%) - Inspection',
      filterKey: 'Due soon',
      value: 'dueSoon',
    },
    {
      text: 'In Compliance (0 - 79%) - Inspection',
      filterKey: 'In Compliance',
      value: 'inCompliance',
    },
  ];
};

const dvirFilterOptions = () => {
  return [
    {
      text: 'Safe',
      value: 'safe',
    },
    {
      text: 'Unsafe',
      value: 'unsafe',
    },
    {
      text: 'Resolved',
      value: 'resolved',
    },
    {
      text: 'Defects',
      value: 'defects',
    },
  ];
};

const engineLightFilterOptions = () => {
  return [
    { text: 'Check Engine Light', value: 'amberWarningLamp' },
    { text: 'Engine Light', value: 'generalVehicleWarningLight' },
    { text: 'Engine Protect Lamp', value: 'protectWarningLamp' },
    { text: 'MIL Lamp (emissions)', value: 'malfunctionLamp' },
    { text: 'Red Stop Lamp', value: 'redStopLamp' },
  ];
};

const issuesFilterOptions = () => {
  return [
    { text: 'Critical', value: 'critical' },
    { text: 'Major', value: 'major' },
    { text: 'Minor', value: 'minor' },
  ];
};

const defaultSubLocation = [
  { key: '041-UFPT', name: '041-UFPT' },
  { key: '209-Eatonton-GA', name: '209-Eatonton-GA' },
  { key: '210-Moultrie-GA', name: '210-Moultrie-GA' },
  { key: '210-Moultrie-GA (Plant Only)', name: '210-Moultrie-GA (Plant Only)' },
  { key: '211-UnionCity-GA ', name: '211-UnionCity-GA ' },
  { key: '212-Gordon-PA', name: '212-Gordon-PA' },
  { key: '214-Salisbury-NC', name: '214-Salisbury-NC' },
  { key: '216-Auburndale-FL', name: '216-Auburndale-FL' },
  { key: '217-Warrens-WI', name: '217-Warrens-WI' },
  { key: '218-Granger-IN', name: '218-Granger-IN' },
  { key: '218-Granger-IN (Plant only)', name: '218-Granger-IN (Plant only)' },
  { key: '219-Janesville-WI', name: '219-Janesville-WI' },
  { key: '221-Belchertown-MA', name: '221-Belchertown-MA' },
  { key: '223-Windsor-CO', name: '223-Windsor-CO' },
  { key: '223-Windsor-CO (Plant only)', name: '223-Windsor-CO (Plant only)' },
  { key: '224-Grandview-TX', name: '224-Grandview-TX' },
  { key: '227-Saginaw-TX', name: '227-Saginaw-TX' },
  { key: '228-Ranson-WV', name: '228-Ranson-WV' },
  { key: '233-Chandler-AZ', name: '233-Chandler-AZ' },
  { key: '250-Lacolle-Quebec', name: '250-Lacolle-Quebec' },
  { key: '251-Harrisonville-MO', name: '251-Harrisonville-MO' },
  { key: '251KS-Salina-KS', name: '251KS-Salina-KS' },
  { key: '253-Hamilton-OH', name: '253-Hamilton-OH' },
  { key: '258-ElizabethCity-NC', name: '258-ElizabethCity-NC' },
  { key: '272-San Antonio-TX', name: '272-San Antonio-TX' },
  { key: '274-Shippenville-PA ', name: '274-Shippenville-PA ' },
  { key: '277-Lafayette-CO', name: '277-Lafayette-CO' },
  { key: '280-Chesapeake-VA', name: '280-Chesapeake-VA' },
  { key: '281-Pearisburg-VA', name: '281-Pearisburg-VA' },
  { key: '282-Burlington-NC', name: '282-Burlington-NC' },
  { key: '286-Ooltewah-TN', name: '286-Ooltewah-TN' },
  { key: '288-Conway-SC', name: '288-Conway-SC' },
  { key: '290-Jefferson-GA', name: '290-Jefferson-GA' },
  { key: '294-Schertz-TX', name: '294-Schertz-TX' },
  { key: '299-New Waverly-TX', name: '299-New Waverly-TX' },
  {
    key: '299-New Waverly-TX (Plant only)',
    name: '299-New Waverly-TX (Plant only)',
  },
  { key: '301-New Windsor-MD', name: '301-New Windsor-MD' },
  { key: '302-Liberty-NC', name: '302-Liberty-NC' },
  { key: '309-Blanchester-OH', name: '309-Blanchester-OH' },
  { key: '315-New London-NC', name: '315-New London-NC' },
  { key: '316-Ashburn-GA', name: '316-Ashburn-GA' },
  { key: '317-Haleyville-AL', name: '317-Haleyville-AL' },
  { key: '325-Auburn-NY', name: '325-Auburn-NY' },
  { key: '326-Sidney-NY', name: '326-Sidney-NY' },
  { key: '327-Hudson-NY', name: '327-Hudson-NY' },
  { key: '336 Lansing-MI', name: '336 Lansing-MI' },
  { key: '349-ASL-Berlin-NJ', name: '349-ASL-Berlin-NJ' },
  { key: '349-Berlin-NJ', name: '349-Berlin-NJ' },
  { key: '371-Morristown-TN', name: '371-Morristown-TN' },
  {
    key: '371-Morristown-TN (Plant only)',
    name: '371-Morristown-TN (Plant only)',
  },
  { key: '374-Hillsboro-TX', name: '374-Hillsboro-TX' },
  { key: '379-Minneota-MN', name: '379-Minneota-MN' },
  { key: '380-Woodburn-OR', name: '380-Woodburn-OR' },
  { key: '382-Riverside-CA', name: '382-Riverside-CA' },
  { key: '397-Cordele-GA', name: '397-Cordele-GA' },
  { key: '398-Athena-OR', name: '398-Athena-OR' },
  { key: '398-Elkhart-IN', name: '398-Elkhart-IN' },
  { key: '398-Haleyville-AL', name: '398-Haleyville-AL' },
  { key: '404-Snohomish-WA', name: '404-Snohomish-WA' },
  { key: '406-Yakima-WA', name: '406-Yakima-WA' },
  { key: '407-Folkston-GA', name: '407-Folkston-GA' },
  { key: '407-Folkston-GA (Plant only)', name: '407-Folkston-GA (Plant only)' },
  { key: '412-Gilmore-TX', name: '412-Gilmore-TX' },
  { key: '417-Locust-NC', name: '417-Locust-NC' },
  { key: '418-Franklinton-NC', name: '418-Franklinton-NC' },
  { key: '421-Kyle-TX', name: '421-Kyle-TX' },
  { key: '425-Magna-UT', name: '425-Magna-UT' },
  { key: '428-Shawnee-OK', name: '428-Shawnee-OK' },
  { key: '433-Peru-IL', name: '433-Peru-IL' },
  { key: '435-Nampa-ID', name: '435-Nampa-ID' },
  { key: '436-Rockwell-NC', name: '436-Rockwell-NC' },
  { key: '438-Thomaston-GA', name: '438-Thomaston-GA' },
  { key: '439-Orlando-FL', name: '439-Orlando-FL' },
  { key: '440-Tampa-FL', name: '440-Tampa-FL' },
  { key: '458-Nappanee-IN', name: '458-Nappanee-IN' },
  { key: '469-Barnesville-GA', name: '469-Barnesville-GA' },
  { key: '470-Clinton-NC', name: '470-Clinton-NC' },
  { key: '471-Blue Island-IL', name: '471-Blue Island-IL' },
  { key: '471-Chicago-IL (Plant Only)', name: '471-Chicago-IL (Plant Only)' },
  { key: '479-Orangeburg-SC', name: '479-Orangeburg-SC' },
  { key: '482-Adairsville-GA', name: '482-Adairsville-GA' },
  { key: '485-Martin-TN', name: '485-Martin-TN' },
  { key: '487-Grand Rapids-MI', name: '487-Grand Rapids-MI' },
  { key: '492-Hartford-WI ', name: '492-Hartford-WI ' },
  {
    key: '493-T&R-Rancho Cucamonga-CA & Woodburn-OR',
    name: '493-T&R-Rancho Cucamonga-CA & Woodburn-OR',
  },
  { key: '501-Hazlehurst-GA', name: '501-Hazlehurst-GA' },
  { key: '501-Southeast', name: '501-Southeast' },
  { key: '503-Midwest', name: '503-Midwest' },
  { key: '504-Southwest', name: '504-Southwest' },
  { key: '505-Central Plains', name: '505-Central Plains' },
  { key: '512-Wilton-NH', name: '512-Wilton-NH' },
  { key: '513-Stanfield-NC', name: '513-Stanfield-NC' },
  { key: '514-Bonner-MT', name: '514-Bonner-MT' },
  { key: '523-Bartow-FL', name: '523-Bartow-FL' },
  { key: '524-Rockledge-FL', name: '524-Rockledge-FL' },
  { key: '525-Louisville-AL', name: '525-Louisville-AL' },
  { key: '527-Athens-AL', name: '527-Athens-AL' },
  { key: '529-Bartow-FL', name: '529-Bartow-FL' },
  { key: '530-Hazlehurst-GA', name: '530-Hazlehurst-GA' },
  { key: '532-Smarr-GA', name: '532-Smarr-GA' },
  { key: '533-Selma-AL', name: '533-Selma-AL' },
  { key: '534-Robertsdale-AL', name: '534-Robertsdale-AL' },
  { key: '541-New London-WI', name: '541-New London-WI' },
  { key: '544-New Boston-TX', name: '544-New Boston-TX' },
  { key: '546-Clarksville-TX', name: '546-Clarksville-TX' },
  { key: '547-Livermore Falls-ME', name: '547-Livermore Falls-ME' },
  { key: '551-Mosheim-TN', name: '551-Mosheim-TN' },
  { key: '554-Moneta-VA', name: '554-Moneta-VA' },
  { key: '565-Temple-TX', name: '565-Temple-TX' },
  { key: '575-Tipton-IA', name: '575-Tipton-IA' },
  { key: '579 - Delono-PA', name: '579 - Delono-PA' },
  { key: '581-FlowerMound-TX', name: '581-FlowerMound-TX' },
  { key: '584-Berthoud-CO', name: '584-Berthoud-CO' },
  { key: '586-Parker-PA', name: '586-Parker-PA' },
  { key: '586-Parker, PA (Plant only)', name: '586-Parker, PA (Plant only)' },
  { key: '588-Chicopee-MA', name: '588-Chicopee-MA' },
  {
    key: '590-Tooele-UT (w/3rd Party Hauler)',
    name: '590-Tooele-UT (w/3rd Party Hauler)',
  },
  { key: 'Decommissioned', name: 'Decommissioned' },
];

const defaultSubLocationSWest = [
  { key: 'American Fork', name: 'American Fork' },
  { key: 'Bear Lake', name: 'Bear Lake' },
  { key: 'Erda', name: 'Erda' },
  { key: 'Hyrum', name: 'Hyrum' },
  { key: 'Kil - Double Bellies', name: 'Kil - Double Bellies' },
  { key: 'Kil - Strong Arm', name: 'Kil - Strong Arm' },
  { key: 'Kil - Transport', name: 'Kil - Transport' },
  { key: 'Kil - Truck and Pup', name: 'Kil - Truck and Pup' },
  { key: 'Magna', name: 'Magna' },
  { key: 'Manti', name: 'Manti' },
  { key: 'Michele Bronson - UT', name: 'Michele Bronson - UT' },
  { key: 'Mona', name: 'Mona' },
  { key: 'North Salt Lake', name: 'North Salt Lake' },
  { key: 'Ogden', name: 'Ogden' },
  { key: 'Springville', name: 'Springville' },
  { key: 'Talons Cove', name: 'Talons Cove' },
  {
    key: 'Tim Jackovich - Traffic Control - UT',
    name: 'Tim Jackovich - Traffic Control - UT',
  },
  { key: 'TJ Barrow-Pave/ Grade', name: 'TJ Barrow-Pave/ Grade' },
  { key: 'TJ Barrow-Pave/ Grade - UT', name: 'TJ Barrow-Pave/ Grade - UT' },
  { key: 'Tremonton', name: 'Tremonton' },
  { key: 'West Jordan', name: 'West Jordan' },
  { key: 'West Valley', name: 'West Valley' },
];

const defaultSubLocation1429 = [
  { key: 'Kil - Double Bellies', name: 'Kil - Double Bellies' },
  { key: 'Kil - Strong Arm', name: 'Kil - Strong Arm' },
  { key: 'Kil - Transport', name: 'Kil - Transport' },
  { key: 'Kil - Truck and Pup', name: 'Kil - Truck and Pup' },
  { key: 'Michele Bronson - UT', name: 'Michele Bronson - UT' },
  {
    key: 'Tim Jackovich - Traffic Control - UT',
    name: 'Tim Jackovich - Traffic Control - UT',
  },
  { key: 'TJ Barrow-Pave/ Grade', name: 'TJ Barrow-Pave/ Grade' },
  { key: 'TJ Barrow-Pave/ Grade - UT', name: 'TJ Barrow-Pave/ Grade - UT' },
];

const defaultSubLocation1430 = [
  { key: 'American Fork', name: 'American Fork' },
  { key: 'Bear Lake', name: 'Bear Lake' },
  { key: 'Erda', name: 'Erda' },
  { key: 'Hyrum', name: 'Hyrum' },
  { key: 'Manti', name: 'Manti' },
  { key: 'Mona', name: 'Mona' },
  { key: 'North Salt Lake', name: 'North Salt Lake' },
  { key: 'Ogden', name: 'Ogden' },
  { key: 'Springville', name: 'Springville' },
  { key: 'Talons Cove', name: 'Talons Cove' },
  { key: 'Tremonton', name: 'Tremonton' },
  { key: 'West Jordan', name: 'West Jordan' },
  { key: 'West Valley', name: 'West Valley' },
];

const subLocationOptions = (shopId: number) => {
  const shopMappings: { [key: number]: { key: string; name: string }[] } = {
    1361: defaultSubLocationSWest,
    1424: defaultSubLocation,
    1429: defaultSubLocation1429, // Specific mappings for 1429
    1430: defaultSubLocation1430,
    1460: defaultSubLocation,
    1472: [
      { key: '209-Eatonton-GA', name: '209-Eatonton-GA' },
      { key: '210-Moultrie-GA', name: '210-Moultrie-GA' },
      { key: '211-UnionCity-GA', name: '211-UnionCity-GA' },
      { key: '216-Auburndale-FL', name: '216-Auburndale-FL' },
      { key: '290-Jefferson-GA', name: '290-Jefferson-GA' },
      { key: '316-Ashburn-GA', name: '316-Ashburn-GA' },
      { key: '397-Cordele-GA', name: '397-Cordele-GA' },
      { key: '407-Folkston-GA', name: '407-Folkston-GA' },
      { key: '437-Gainesville-GA', name: '437-Gainesville-GA' },
      { key: '438-Thomaston-GA', name: '438-Thomaston-GA' },
      { key: '439-Orlando-FL', name: '439-Orlando-FL' },
      { key: '440-Tampa-FL', name: '440-Tampa-FL' },
      { key: '469-Barnesville-GA', name: '469-Barnesville-GA' },
      { key: '482-Adairsville-GA', name: '482-Adairsville-GA' },
      { key: '491-Miami-FL', name: '491-Miami-FL' },
      { key: '501-Hazlehurst-GA', name: '501-Hazlehurst-GA' },
      { key: '523-Bartow-FL', name: '523-Bartow-FL' },
      { key: '524-Rockledge-FL', name: '524-Rockledge-FL' },
      { key: '529-Bartow-FL', name: '529-Bartow-FL' },
      { key: '530-Hazlehurst-GA', name: '530-Hazlehurst-GA' },
      { key: '532-Smarr-GA', name: '532-Smarr-GA' },
      { key: '569-Brunswick-GA', name: '569-Brunswick-GA' },
    ],
    1473: [
      { key: '212-Gordon-PA', name: '212-Gordon-PA' },
      { key: '221-Belchertown-MA', name: '221-Belchertown-MA' },
      { key: '228-Ranson-WV', name: '228-Ranson-WV' },
      { key: '250-Lacolle-Quebec', name: '250-Lacolle-Quebec' },
      { key: '255-Stockertown-PA', name: '255-Stockertown-PA' },
      { key: '274-Shippenville-PA', name: '274-Shippenville-PA' },
      { key: '280-Chesapeake-VA', name: '280-Chesapeake-VA' },
      { key: '281-Pearisburg-VA', name: '281-Pearisburg-VA' },
      { key: '301-New Windsor-MD', name: '301-New Windsor-MD' },
      { key: '324-Chaffee-NY', name: '324-Chaffee-NY' },
      { key: '325-Auburn-NY', name: '325-Auburn-NY' },
      { key: '326-Sidney-NY', name: '326-Sidney-NY' },
      { key: '327-Hudson-NY', name: '327-Hudson-NY' },
      { key: '349-Berlin-NJ', name: '349-Berlin-NJ' },
      { key: '512-Wilton-NH', name: '512-Wilton-NH' },
      { key: '547-Livermore Falls-ME', name: '547-Livermore Falls-ME' },
      { key: '553-Hampton-VA', name: '553-Hampton-VA' },
      { key: '554-Moneta-VA', name: '554-Moneta-VA' },
      { key: '579-Delono-PA', name: '579-Delono-PA' },
      { key: '586-Parker-PA', name: '586-Parker-PA' },
      { key: '588-Chicopee-MA', name: '588-Chicopee-MA' },
    ],
    1474: [
      { key: '217-Warrens-WI', name: '217-Warrens-WI' },
      { key: '218-Granger-IN', name: '218-Granger-IN' },
      { key: '219-Janesville-WI', name: '219-Janesville-WI' },
      { key: '251-Harrisonville-MO', name: '251-Harrisonville-MO' },
      { key: '251KS-Salina-KS', name: '251KS-Salina-KS' },
      { key: '253-Hamilton-OH', name: '253-Hamilton-OH' },
      { key: '309-Blanchester-OH', name: '309-Blanchester-OH' },
      { key: '336-Lansing-MI', name: '336-Lansing-MI' },
      { key: '379-Minneota-MN', name: '379-Minneota-MN' },
      { key: '398-Elkhart-IN', name: '398-Elkhart-IN' },
      { key: '408-Sauk Rapids-MN', name: '408-Sauk Rapids-MN' },
      { key: '420-Eagen-MN', name: '420-Eagen-MN' },
      { key: '431-Kansas City-MO', name: '431-Kansas City-MO' },
      { key: '433-Peru-IL', name: '433-Peru-IL' },
      { key: '458-Nappanee-IN', name: '458-Nappanee-IN' },
      { key: '471-Blue Island-IL', name: '471-Blue Island-IL' },
      { key: '486-Milwaukee-WI', name: '486-Milwaukee-WI' },
      { key: '487-Grand Rapids-MI', name: '487-Grand Rapids-MI' },
      { key: '492-Hartford-WI', name: '492-Hartford-WI' },
      { key: '541-New London-WI', name: '541-New London-WI' },
      { key: '575-Tipton-IA', name: '575-Tipton-IA' },
    ],
    1475: [
      { key: '223-Windsor-CO', name: '223-Windsor-CO' },
      { key: '233-Chandler-AZ', name: '233-Chandler-AZ' },
      { key: '277-Lafayette-CO', name: '277-Lafayette-CO' },
      { key: '380-Woodburn-OR', name: '380-Woodburn-OR' },
      { key: '382-Riverside-CA', name: '382-Riverside-CA' },
      { key: '388-McMinnville-OR', name: '388-McMinnville-OR' },
      { key: '398-Athena-OR', name: '398-Athena-OR' },
      { key: '404-Snohomish-WA', name: '404-Snohomish-WA' },
      { key: '405-Wenatchee-WA', name: '405-Wenatchee-WA' },
      { key: '406-Malaga-WA', name: '406-Malaga-WA' },
      { key: '406-Yakima-WA', name: '406-Yakima-WA' },
      { key: '425-Magna-UT', name: '425-Magna-UT' },
      { key: '435-Nampa-ID', name: '435-Nampa-ID' },
      { key: '493-Rancho Cucamonga-CA', name: '493-Rancho Cucamonga-CA' },
      { key: '514-Bonner-MT', name: '514-Bonner-MT' },
      { key: '584-Berthoud-CO', name: '584-Berthoud-CO' },
      { key: '590-Tooele-UT', name: '590-Tooele-UT' },
    ],
    1476: [
      { key: '224-Grandview-TX', name: '224-Grandview-TX' },
      { key: '227-Saginaw-TX', name: '227-Saginaw-TX' },
      { key: '272-San Antonio-TX', name: '272-San Antonio-TX' },
      { key: '294-Schertz-TX', name: '294-Schertz-TX' },
      { key: '299-New Waverly-TX', name: '299-New Waverly-TX' },
      { key: '374-Hillsboro-TX', name: '374-Hillsboro-TX' },
      { key: '412-Gilmore-TX', name: '412-Gilmore-TX' },
      { key: '421-Kyle-TX', name: '421-Kyle-TX' },
      { key: '428-Shawnee-OK', name: '428-Shawnee-OK' },
      { key: '544-New Boston-TX', name: '544-New Boston-TX' },
      { key: '546-Clarksville-TX', name: '546-Clarksville-TX' },
      { key: '565-Temple-TX', name: '565-Temple-TX' },
      { key: '574-Idabel-TX', name: '574-Idabel-TX' },
      { key: '581-FlowerMound-TX', name: '581-FlowerMound-TX' },
      { key: '582-FlowerMound-TX', name: '582-FlowerMound-TX' },
    ],
    1477: [
      { key: '214-Salisbury-NC', name: '214-Salisbury-NC' },
      { key: '258-ElizabethCity-NC', name: '258-ElizabethCity-NC' },
      { key: '282-Burlington-NC', name: '282-Burlington-NC' },
      { key: '286-Ooltewah-TN', name: '286-Ooltewah-TN' },
      { key: '288-Conway-SC', name: '288-Conway-SC' },
      { key: '302-Liberty-NC', name: '302-Liberty-NC' },
      { key: '315-New London-NC', name: '315-New London-NC' },
      { key: '317-Haleyville-AL', name: '317-Haleyville-AL' },
      { key: '371-Morristown-TN', name: '371-Morristown-TN' },
      { key: '398-Haleyville-AL', name: '398-Haleyville-AL' },
      { key: '417-Locust-NC', name: '417-Locust-NC' },
      { key: '418-Franklinton-NC', name: '418-Franklinton-NC' },
      { key: '436-Rockwell-NC', name: '436-Rockwell-NC' },
      { key: '470-Clinton-NC', name: '470-Clinton-NC' },
      { key: '479-Orangeburg-SC', name: '479-Orangeburg-SC' },
      { key: '485-Martin-TN', name: '485-Martin-TN' },
      { key: '513-Stanfield-NC', name: '513-Stanfield-NC' },
      { key: '525-Louisville-AL', name: '525-Louisville-AL' },
      { key: '527-Athens-AL', name: '527-Athens-AL' },
      { key: '533-Selma-AL', name: '533-Selma-AL' },
      { key: '534-Robertsdale-AL', name: '534-Robertsdale-AL' },
      { key: '538-Butner-NC', name: '538-Butner-NC' },
      { key: '551-Mosheim-TN', name: '551-Mosheim-TN' },
    ],
  };

  return shopMappings[shopId] || [];
};

export {
  nextPMFilterOptions,
  safetyInspectionsFilterOptions,
  dvirFilterOptions,
  engineLightFilterOptions,
  issuesFilterOptions,
  subLocationOptions,
};
