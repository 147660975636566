import React, { Component, createRef } from 'react';
import { withRouter } from 'react-router';
import { computed, decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { PitstopButton, PitstopModal } from 'shared';
import axios from 'axios';
import Tiff from 'tiff.js';

import {
  Row,
  Col,
  notification,
  Spin,
  Button,
  Typography,
  InputNumber,
  Input,
  Icon,
  Checkbox,
  Collapse,
  Tabs,
  Select,
  Modal,
} from 'antd';
import _, { deburr, first, get, isArray, isEmpty, kebabCase } from 'lodash';
import moment from 'moment';
import {
  WorkOrderStore,
  TableStore,
  ShopStore,
  UserStore,
  CurrentUserStore,
  CarStore,
  AppStore,
  IssueStore,
  ContactStore,
  PurchaseOrderStore,
} from 'stores';

import { customFleets } from 'shared';
import WorkOrderForm from 'components/WorkOrder/WODetailsForm';
// import WorkOrderIssuesSummary from 'components/WorkOrder/WOIssuesSummary';
import WorkOrderLineItems from 'components/WorkOrder/LineItems/WorkOrderLineItems';
import WorkOrderCostSummary from 'components/WorkOrder/WorkOrderCostSummary';
import DealershipChooser from 'components/DealershipChooser';

import { PitstopTableCacheStore } from 'stores/CacheStore';
import { webServiceProvider } from 'shared';
import UploadImageModal from './modals/UploadImageModal';
import { Logger } from 'stores/Classes';
import { Link } from 'react-router-dom';
import { WorkOrderProvider } from './WorkOrderDetailsContext';
import WorkOrderOptionalInformation from '../../components/WorkOrder/WorkOrderOptionalInformation';
import { checkIfMobileScreenSize, getScreenSize } from 'shared/utils';
import FlexRow from 'components/General/FlexRow';
import MediaPreview from 'components/General/MediaPreview';
import NotificationBanner from 'components/NotificationBanner';
import PurchaseOrderAddModal from 'components/PurchaseOrders/PurchaseOrderAddModal';

const { Text } = Typography;
const { TextArea } = Input;
const { Panel } = Collapse;

const ImagePreviewModal = styled(Modal)`
  &&& {
    height: 100%;
    width: 70vw !important;

    @media (max-width: 768px) {
      width: 100vw !important;
    }
  }

  .ant-modal-content {
    height: 100%;
    width: 100%;
  }

  .ant-modal-body {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const StyledModal = styled(PitstopModal)`
  && {
    .ant-modal-header {
      background: #02103d;

      .ant-modal-title {
        color: #ffffff;
      }
    }
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 25px;
`;

const Container = styled.div`
  padding-right: 2vw;
`;

const ActionButtonsWrapper = styled.div`
  right: 15px;

  button {
    margin-left: 10px;
  }

  @media screen and (max-width: 1024px) {
    .small-screen {
      font-size: 13px;
    }
  }
`;

const ActionButtonsWrapperFlexEnd = styled(ActionButtonsWrapper)`
  display: flex;
  justify-content: flex-end;
`;

const UploadButton = styled(Button)`
  background-color: #fff;
  border: 1px solid #1890ff;
  color: #1890ff;
  &:hover {
    background-color: #ecf6fd;
    border-color: #1890ff;
  }
  &:focus,
  &:active {
    background-color: #fff;
    border-color: #1890ff;
  }
`;

const ImportedInformation = styled.div`
  background-color: #f0f0f0;
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 4px;
  p {
    margin: 0;
  }
`;

const ADD_PART_MODAL = 'ADD_PART_MODAL';

class WorkOrderDetails extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object,
  };

  constructor (props) {
    super(props);
    this.fetchBase64FileAndRender = this.fetchBase64FileAndRender.bind(this);
  }

  currentDateTime = moment();
  randomWorkOrderId = () => _.padStart(_.random(999999), '0');

  usersTableStore = new TableStore();

  shopId = ShopStore.currentShop.id;

  get shopUsers () {
    const users = _.map(this.usersTableStore.data, (id) =>
      UserStore.data.get(id)
    );
    return users;
  }

  state = {
    isEdit: false,
    loadingAssetDefects: false,
    assetDefects: [],
    activeTab: 'work-order-details', // when this is set to optional-information, work order scan works
    lineItems: [],
    purchaseOrders: [],
    addPOModalVisible: false,
    costsTotalHasManualOverride: false,
    laboursTotalHasManualOverride: false,
    partsTotalHasManualOverride: false,
    isImagePreviewModalVisible: false,
    currentImagePreviewSrc: '',
    manualOverrides: {
      manualLaboursTotal: 0,
      manualPartsTotal: 0,
      manualCostsTotal: 0,
    },
    currentCarIssues: [],
    selectedCarId: undefined,
    savingWorkOrder: false,
    currentWorkOrder: null,
    loadingWorkOrder: true,
    loadingWorkOrderLines: true,
    loadingInitial: true,
    title: '',
    initialFormValues: {
      woInvoiceNumber: this.randomWorkOrderId(),
      invoiceNumberCustomer: '',
      woCreatedBy: '',
      woSelectVehicle: undefined,
      woAssetType: checkIfMobileScreenSize() ? 'Trailer' : 'Vehicle',
      woAssignedTo: '',
      woStatus: '',
      woRepairType: 'driver_identified',
      woRepairPriority: 'minor',
      woIssueReportDate: this.currentDateTime,
      purchase_order_id: undefined,
      additionalNotes: '',
      driverReportDetails: {
        driverName: '',
        driverNumber: '',
        location: '',
        timeOfEvent: null,
        weightOfVehicle: '',
        driverNotes: '',
        billingThrough: null,
      },
      woStartDate: undefined,
      woCompletedDate: undefined,
      mileage: undefined,
      engineHours: undefined,
      locationCode: undefined,
      tax: {
        type: 'percentage',
        value: 0,
      },
      misc: {
        type: 'percentage',
        value: 0,
      },
      warrantySavings: 0,
      woSource: '',
      rawData: '',
      laborsTotal: 0,
      partsTotal: 0,
      costsTotal: 0,
      vendor_id: undefined,
    },
    shopUsers: [],
    shopParts: [],
    shopVendors: [],
    carDetails: {},
    newPart: {
      name: '',
      description: '',
      number: '',
      cost: 0,
    },
    loadingSavingNewPart: false,
    currentLinePartIsBeingAdded: null,
    uploadedFiles: [],
    uploadedImages: [],
    wo_images: [],
    wo_documents: [],
    showUploadButton: true,
    isUploadWorkOrderModalVisible: false,
    isLoadingWorkOrderUpload: false,
    isErrorWorkOrderUpload: false,
    importedInformation: null,
    scannedWorkOrderPreview: null,
    isDisabled: false,
    uploading: false,
    progress: 0,
    isMobileScreenSize: checkIfMobileScreenSize(),
    isLabourWarning: false,
    isPartsWarning: false,
    isPartsCostDiscrepancy: false,
    isLaborCostDiscrepancy: false,
    screenSize: getScreenSize(),
    workOrderImgOcrSignal: null,
  };

  workOrderFormRef = createRef();
  lineItemsRef = createRef();
  costSummaryRef = createRef();
  imageUploaderContainerRef = {};
  documentUploaderContainerRef = {};

  setImageUploaderContainerRef = (ref) => {
    this.imageUploaderContainerRef = ref;
  };

  setDocumentUploaderContainerRef = (ref) => {
    this.documentUploaderContainerRef = ref;
  };

  orderedSections = [
    'work-order-details',
    'work-order-line-items',
    'optional-information',
    'summary',
  ];

  forceUpdateTabs () {
    this.forceUpdate(); // Force a re-render of the component
  }

  scrollContainer;

  async componentDidMount () {
    AppStore.setSelectedSidebarKey('/work-order');

    this.load();

    // check if there is a 'tab' query param
    const queryParams = new URLSearchParams(this.props.history.location.search);
    const tab = queryParams.get('tab');
    if (tab) {
      this.setState({ activeTab: tab });
    }

    // on window resize, check if the screen size is mobile
    window.addEventListener('resize', this.handleResize);

    // get element main.ant-layout-content
    this.scrollContainer = document.getElementsByClassName(
      'dashboard-content'
    )[0];
    // console.log(this.scrollContainer)
    if (this.scrollContainer) {
      this.scrollContainer.addEventListener('scroll', this.handleScroll);
    }
  }

  s3UriToHttpUrl = (s3Uri) => {
    if (!s3Uri) {
      console.error("S3 URI is null or undefined");
      return null;
    }

    if (!s3Uri.startsWith("s3://")) {
      console.error("Invalid S3 URI format");
      return null;
    }

    const bucketName = "work-orders-scan";
    const region = "us-east-2";
    const objectKey = s3Uri.replace(`s3://${bucketName}/`, "");

    if (!objectKey) {
      console.error("S3 URI does not contain an object key");
      return null;
    }

    return `https://${bucketName}.s3.${region}.amazonaws.com/${objectKey}`;
  };

  fetchBase64FileAndRender = (url, type = "png") => {
    const shopId = ShopStore.currentShop.id;

    const options = {
      method: "GET",
      headers: {
        Accept: "*/*", // Specify the Accept header
      },
      url,
    };

    const supportedTypes = {
      png: "data:image/png;base64,",
      jpeg: "data:image/jpeg;base64,",
      jpg: "data:image/jpeg;base64,",
      pdf: "data:application/pdf;base64,",
      tiff: "data:image/tiff;base64,",
      tif: "data:image/tiff;base64,",
    };

    if (type.toLowerCase() === "jpeg" || type.toLowerCase() === "jpg") {
      if(customFleets.ufpFleet.includes(shopId)) {
        type = 'tiff';
      }
    }

    const base64Prefix = supportedTypes[type.toLowerCase()] || "data:image/png;base64,";

    axios
      .request(options)
      .then((response) => {
        const base64Data = response.data.trim(); // Remove any extra whitespace

        if (type.toLowerCase() === "tiff" || type.toLowerCase() === "tif") {
          // Handle TIFF specifically
          const arrayBuffer = Uint8Array.from(atob(base64Data), (char) => char.charCodeAt(0)).buffer;
          const tiff = new Tiff({ buffer: arrayBuffer });
          const canvas = tiff.toCanvas(); // Convert TIFF to a canvas element

          if (canvas) {
            const tiffImageDataUrl = canvas.toDataURL(); // Convert canvas to data URL
            this.setState({ scannedWorkOrderPreview: tiffImageDataUrl });
          } else {
            console.error("Failed to convert TIFF to canvas.");
          }
        } else {
          // Default handling for other types
          const formattedBase64Data = base64Data.startsWith("data:")
            ? base64Data
            : `${base64Prefix}${base64Data}`;

          this.setState({ scannedWorkOrderPreview: formattedBase64Data });
        }

        this.scrollToTopAndSaveOriginalMediaPreviewData();
      })
      .catch((error) => {
        console.error("Error fetching base64 data:", error);
      });
  };

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleResize);
    if (this.scrollContainer) {
      this.scrollContainer.removeEventListener('scroll', this.handleScroll);
    }
  }

  handleResize = () => {
    this.setState({
      isMobileScreenSize: checkIfMobileScreenSize(),
      screenSize: getScreenSize(),
    });
  };

  originalMediaPreviewTopDistance = 0;
  originalMediaPreviewWidth = 0;
  scrollToTopAndSaveOriginalMediaPreviewData = () => {
    if (this.scrollContainer) {
      this.scrollContainer.scrollTop = 0;
    }
    setTimeout(() => {
      const mediaPreview = document.getElementById('media-preview');
      if (mediaPreview) {
        this.originalMediaPreviewTopDistance = mediaPreview.getBoundingClientRect().top;
        this.originalMediaPreviewWidth = mediaPreview.offsetWidth;
      }
    }, 200);
  };

  handleScroll = (event) => {
    const mediaPreview = document.getElementById('media-preview');
    if (!mediaPreview) {
      return;
    }
    // distance from the top of the media preview to the top of the target element
    const mediaPreviewTop = mediaPreview.getBoundingClientRect().top;
    const scrollContainerScrollTop = event.target.scrollTop;

    const originalDistanceMinusHeader =
      this.originalMediaPreviewTopDistance - 70;

    if (scrollContainerScrollTop < originalDistanceMinusHeader) {
      mediaPreview.classList.remove('fixed-top');
    } else if (mediaPreviewTop < 70) {
      mediaPreview.classList.add('fixed-top');
      // force the media preview to have the same width as the original
      mediaPreview.style.width = `${this.originalMediaPreviewWidth}px`;
    }
  };

  loadShopUsers = async () => {
    await UserStore.fetchUsersForTable(
      {
        offset: 0,
        limit: 100,
        filter: null,
      },
      this.usersTableStore
    );
  };

  loadShopPurchaseOrders = async () => {
    try {
      if (
        !ShopStore.currentShop.id ||
        ShopStore.currentShop.id === '-1' ||
        ShopStore.currentShop.id === -1
      ) {
        this.setState({ purchaseOrders: [] });
        return;
      }
      const purchaseOrders = await PurchaseOrderStore.fetchShopPurchaseOrders(
        ShopStore.currentShop.id
      );
      this.setState({ purchaseOrders });
    } catch (error) {
      Logger.error(error);
    }
  };

  loadShopParts = async () => {
    try {
      const shopParts = await WorkOrderStore.getShopSpecificParts(
        ShopStore.currentShop.id,
        {
          include_deleted: true,
        }
      );
      this.setState({ shopParts });
    } catch (error) {
      console.log(error.name, error);
      // check if error is abort error
      if (error.name === 'AbortError') {
        return;
      }
      return notification.error({
        message: 'Oops!',
        key: 'listShopParts',
        description: (
          <Container>Sorry, we couldn't fetch your Parts.</Container>
        ),
      });
    }
  };

  loadShopVendors = async () => {
    try {
      if (
        !ShopStore.currentShop.id ||
        ShopStore.currentShop.id === '-1' ||
        ShopStore.currentShop.id === -1
      ) {
        this.setState({ shopVendors: [] });
        return;
      }
      const shopVendors = await ContactStore.getShopContacts(
        ShopStore.currentShop.id
      );
      this.setState({ shopVendors });
    } catch (error) {
      Logger.error(error);
    }
  };

  hydrateLineItem = (lineItem, index) => {
    return {
      ...lineItem,
      title: lineItem.title || `New Work Order Line ${index + 1}`,
      header: lineItem.title || `New Work Order Line ${index + 1}`,
      key: lineItem.id || lineItem.key || index + 1,
      laborTotal: parseFloat(lineItem.labour_cost || 0),
      partsTotal: parseFloat(lineItem.parts_cost || 0),
      vmrs_asm_code_id: lineItem.vmrs_asm_code_id,
      vmrs_sys_code_id: lineItem.vmrs_sys_code_id,
      parts: lineItem.parts
        ? lineItem.parts.map((p, i) => ({
          ...p,
          originalAmount: parseFloat(p.amount || 0),
          key: parseInt(`${i + 1}${p.id}`),
          name: `Part ${parseInt(`${i + 1}${p.id}`)}`,
          markup: parseFloat(p.markup || 0),
          cost: parseFloat(p.cost || 0),
        }))
        : [],
      labours: lineItem.labours
        ? lineItem.labours.map((p, i) => ({
          ...p,
          key: parseInt(`${i + 1}${p.id}`),
          name: `Labor ${parseInt(`${i + 1}${p.id}`)}`,
          cost: parseFloat(p.cost || 0),
        }))
        : [],
      services: lineItem.services
        ? lineItem.services.map((p, i) => ({
          ...p,
          key: parseInt(`${i + 1}${p.id}`),
        }))
        : [],
      miscellaneous: lineItem.miscellaneous
        ? lineItem.miscellaneous.map((p, i) => ({
          ...p,
          key: parseInt(`${i + 1}${p.id}`),
        }))
        : [],
      defects: lineItem.defects || [],
      expanded: true,
    };
  };

  hydrateLineItems = (lineItems = []) => {
    return lineItems.map(this.hydrateLineItem);
  };

  get queryAssetId () {
    const queryParams = new URLSearchParams(this.props.history.location.search);
    return queryParams.get('assetId');
  }

  load = async () => {
    this.setState({ loadingInitial: true });
    await this.loadShopUsers();
    await this.loadShopVendors();
    await this.loadShopPurchaseOrders();
    await this.loadShopParts();
    this.setState({ loadingInitial: false });

    const id = this.props.match.params.id;
    if (id) {
      this.setState({ title: 'Edit', isEdit: true });
      // get work order by ID
      try {
        this.setState({ loadingWorkOrder: true });
        const workOrder = await WorkOrderStore.getWorkOrderById(id);
        if (!workOrder) {
          throw new Error('Work order not found');
        }

        const scannedWorkOrderFile = workOrder.scannedWorkOrder && workOrder.scannedWorkOrder[0]?.s3_file
        ? this.transformS3Url(workOrder.scannedWorkOrder[0]?.s3_file)
        : null;

        if (scannedWorkOrderFile) {
          if (!Array.isArray(workOrder.wo_files?.wo_images)) {
            workOrder.wo_files = { ...workOrder.wo_files, wo_images: [] };
          }
          workOrder.wo_files.wo_images.unshift(scannedWorkOrderFile);
        }
        // remove the cache from Issues table... to refresh the list in other components
        PitstopTableCacheStore.deleteKey(`${workOrder.id_car}-wo-issues-list`);
        // Check and update importedInformation with the first scanned work order
        // let importedInformation = null;

        this.setState({
          selectedCarId: workOrder.id_car,
          // importedInformation,
          currentWorkOrder: {
            ...workOrder,
            // those values are not being used in the first tab form
            // they are being used in the optional-information tab
            woStartDate: workOrder.started_at
              ? moment(workOrder.started_at)
              : null,
            woCompletedDate: workOrder.done_at
              ? moment(workOrder.done_at)
              : null,
            mileage: workOrder.mileage,
            engineHours: workOrder.engine_hours,
            locationCode: workOrder.location_code,
            vendor_id: workOrder.vendor_id,
            woIssueReportDate: workOrder.report_at
              ? moment(workOrder.report_at)
              : null,
            purchase_order_id: workOrder.purchase_order_id,
            additionalNotes: workOrder.additional_notes,
            driverReportDetails: workOrder.driver_report_details
              ? {
                ...workOrder.driver_report_details,
                timeOfEvent: workOrder.driver_report_details.timeOfEvent
                  ? moment(workOrder.driver_report_details.timeOfEvent)
                  : null,
              }
              : {
                driverName: '',
                driverNumber: '',
                location: '',
                timeOfEvent: null,
                weightOfVehicle: '',
                driverNotes: '',
                billingThrough: null,
              },
          },
          lineItems: this.hydrateLineItems(workOrder.work_order_lines),
          initialFormValues: {
            woInvoiceNumber: workOrder.invoice_number,
            invoiceNumberCustomer: workOrder.invoice_number_customer,
            wOid: workOrder.w_ord_id,
            woCreatedBy:
              workOrder.migrate_source !== 'PitStop'
                ? workOrder.migrate_created_by
                : workOrder.created_by,
            woSelectVehicle: workOrder.id_car,
            woAssetType: workOrder.asset_type || 'Trailer', // default to vehicle if asset type was not provided
            woAssignedTo: workOrder.assigned_to,
            woStatus: workOrder.status,
            woRepairType: workOrder.type,
            woRepairPriority: workOrder.priority,
            woIssueReportDate: workOrder.report_at
              ? moment(workOrder.report_at)
              : null,
            woStartDate: workOrder.started_at
              ? moment(workOrder.started_at)
              : null,
            woCompletedDate: workOrder.done_at
              ? moment(workOrder.done_at)
              : null,
            mileage: workOrder.mileage,
            engineHours: workOrder.engine_hours,
            locationCode: workOrder.location_code,
            vendor_id: workOrder.vendor_id,
            purchase_order_id: workOrder.purchase_order_id,
            additionalNotes: workOrder.additional_notes,
            driverReportDetails: workOrder.driver_report_details
              ? {
                ...workOrder.driver_report_details,
                timeOfEvent: workOrder.driver_report_details.timeOfEvent
                  ? moment(workOrder.driver_report_details.timeOfEvent)
                  : null,
              }
              : {
                driverName: '',
                driverNumber: '',
                location: '',
                timeOfEvent: null,
                weightOfVehicle: '',
                driverNotes: '',
                billingThrough: null,
              },
            tax: {
              type: workOrder.tax_type || 'percentage',
              value: workOrder.tax || 0,
            },
            misc: {
              type: workOrder.misc_type || 'percentage',
              value: workOrder.misc || 0,
            },
            warrantySavings: workOrder.warranty_savings || 0,
            woSource: workOrder.migrate_source || 'PitStop',
            rawData:
              workOrder.migrate_source !== 'PitStop' ? workOrder.raw_data : '',
            laborsTotal: workOrder.laborsTotal,
            partsTotal: workOrder.partsTotal,
            costsTotal: workOrder.costsTotal,
          },
          loadingWorkOrder: false,
          wo_images: workOrder.wo_files?.wo_images || [],
          wo_documents: workOrder.wo_files?.wo_documents || [],
          isDisabled: workOrder.migrate_source === 'TMT' ? true : false,
          costsTotalHasManualOverride:
            workOrder?.costs_total_has_manual_override || false,
          laboursTotalHasManualOverride:
            workOrder?.labours_total_has_manual_override || false,
          partsTotalHasManualOverride:
            workOrder?.parts_total_has_manual_override || false,
          manualOverrides: {
            manualLaboursTotal: workOrder?.manual_labours_total || 0,
            manualPartsTotal: workOrder?.manual_parts_total || 0,
            manualCostsTotal: workOrder?.manual_costs_total || 0,
          },
        }, async () => {
          if (
            workOrder.scannedWorkOrder &&
            workOrder.scannedWorkOrder.length > 0 &&
            workOrder.scannedWorkOrder[0]?.scanned
          ) {
            try {
              if (workOrder?.costsTotal === 0){
                await this.loadWorkOrderWithExtractedInformation(
                  JSON.parse(workOrder.scannedWorkOrder[0].scanned),
                  {
                    stringify: true,
                    fillWorkOrderNumber: false,
                  }
                );
              }
              const document_type = workOrder.scannedWorkOrder[0].document_type
              if (workOrder.scannedWorkOrder[0]?.s3_file) {
                const httpUrl = this.s3UriToHttpUrl(workOrder.scannedWorkOrder[0]?.s3_file);
                this.fetchBase64FileAndRender(
                  httpUrl,
                  document_type
                );
              }
            } catch (error) {
              console.error("Failed to parse scannedWorkOrder data:", error);
            }
          }

          // load the DVIR details if there is line items with defects
          if (this.state.lineItems.some(lineItem => lineItem.defects.length > 0)) {
            this.loadDVIRsFromDefects(this.state.lineItems);
          }
        });
      } catch (error) {
        console.log(error);
        let friendlyMsg = 'Work order not found';
        this.setState({ loadingWorkOrder: false });
        return notification.error({
          message: 'Oops!',
          key: 'orderSaveError',
          description: <Container>{friendlyMsg}</Container>,
        });
      }
    } else {
      this.setState({ title: 'Add' });
      this.setState({ loadingWorkOrder: false });
      this.setState({ loadingWorkOrderLines: false });
      // check if query contains assetId
      if (this.queryAssetId) {
        this.setState({
          selectedCarId: +this.queryAssetId,
          initialFormValues: {
            ...this.state.initialFormValues,
            woSelectVehicle: +this.queryAssetId,
          },
        });
      }
    }

    // check if state lineItems is empty. If so, add a new blank hydrated line item
    // if (!this.state.lineItems.length) {
    //   this.onAddLineItem();
    // }
  };

  transformS3Url(s3Url) {
    if (!s3Url.startsWith("s3://")) {
      throw new Error("Invalid S3 URI format");
    }

    // Extract the object key from the S3 URI
    const objectKey = s3Url.replace("s3://work-orders-scan/", "");

    // Perform the necessary transformations on the object key
    const transformedKey = objectKey
      .replace("temp_ufp_", "")
      .replace("_base64.txt", ".jpeg");

    // Construct the final HTTP URL
    const bucketName = "work-orders-scan";
    const region = "us-east-2";
    const finalUrl = `https://${bucketName}.s3.${region}.amazonaws.com/UFP-Workorder/production/${transformedKey}`;

    return finalUrl;
  }
  goBack = () => {
    if (this.queryAssetId) {
      return this.props.history.push(
        `/trailer/${this.queryAssetId}/work-orders`
      );
    }
    return this.props.history.push('/work-order');
  };

  setAssetDefects = (dvirData = {}) => {
    if (!_.isEmpty(dvirData)) {
      const defectsWithDvirId = (dvirData?.payload?.vehicleDefects || [])
        .map(defect => ({
          ...defect,
          dvirId: dvirData.dvirId,
        }));
      // if the defect is already on state.assetDefects, ignore it
      const defectsToAdd = defectsWithDvirId.filter(defect => !this.state.assetDefects.some(
        assetDefect => assetDefect.id === defect.id
      ));
      this.setState({
        assetDefects: [
          ...this.state.assetDefects,
          ...defectsToAdd,
        ],
      });
    } else {
      this.setState({
        assetDefects: [],
      });
    }
  };

  loadAssetDVIRs = async (carDetails = {}) => {
    if (!carDetails?.id) {
      return;
    }
    // check if car is Trailer or Vehicle
    const isTrailer = String(carDetails?.carType).toLowerCase() === 'trailer';
    try {
      this.setState({
        loadingAssetDefects: true,
      });
      const dvirData = await CarStore.fetchDVIRsForCars(carDetails.id, isTrailer);
      if (dvirData.length) {
        const { record } = dvirData[0];
        this.setAssetDefects(record);
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({
        loadingAssetDefects: false,
      });
    }
  };

  loadDVIRsFromDefects = async (lineItems = []) => {
    const defects = lineItems.flatMap(lineItem => lineItem.defects);
    const uniqueDvirIds = [...new Set(defects.map(defect => defect.dvirId))];
    const dvirsResponse = await Promise.all(uniqueDvirIds.map(dvirId => WorkOrderStore.getDvirById(dvirId)));
    const extractedData = dvirsResponse.map(dvir => dvir && dvir.data);
    const dvirs = extractedData.filter(dvir => !_.isEmpty(dvir));
    // for each DVIR, call the setAssetDefects function
    dvirs.forEach(dvir => this.setAssetDefects(dvir));
  }

  onCarSelect = async (selectedCarId) => {
    this.setState({ selectedCarId: +selectedCarId });
    // load car issues
    try {
      const filter = {};
      const id = this.props.match.params.id;
      if (!id) {
        filter.status = 'new'; // only get new issues if adding a new work order
      }
      const carIssues = await IssueStore.getCarIssuesList(
        {
          offset: 0,
          limit: 200,
          filter,
          sort: null,
          includeHistory: false,
        },
        selectedCarId
      );
      this.setState({
        currentCarIssues: carIssues,
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        key: 'carIssues',
        description: <Container>{error.message}</Container>,
      });
    }
  };

  onChangeAssignedTo = (value) => {
    if (this.state.lineItems.length === 0) {
      return this.onAddLineItem([
        {
          key: new Date().getTime(),
          id_labour: value,
          hours: 0,
          cost: 0,
        },
      ]);
    }

    // change the first line item assigned to
    const newLineItems = JSON.parse(JSON.stringify(this.state.lineItems));
    // get the first line item and check if there's a labor already
    if (newLineItems[0].labours.length === 0) {
      newLineItems[0].labours.push({
        key: new Date().getTime(),
        id_labour: value,
        hours: 0,
        cost: 0,
      });
    } else {
      newLineItems[0].labours[0].id_labour = value;
    }

    this.setState({
      lineItems: newLineItems,
    });
  };

  onLocationSelect = (value) => {
    this.setState({
      initialFormValues: {
        ...this.state.initialFormValues,
        locationCode: value,
      },
    });
  };

  onChangePurchaseOrder = (value) => {
    this.setState({
      initialFormValues: {
        ...this.state.initialFormValues,
        purchaseOrder: value,
      },
    });
  };

  onChangeTaxType = (type) => {
    this.setState({
      initialFormValues: {
        ...this.state.initialFormValues,
        tax: {
          ...this.state.initialFormValues.tax,
          type,
        },
      },
    });
  };

  onChangeTaxValue = (value) => {
    this.setState({
      initialFormValues: {
        ...this.state.initialFormValues,
        tax: {
          ...this.state.initialFormValues.tax,
          value,
        },
      },
    });
  };

  onChangeMiscType = (type) => {
    this.setState({
      initialFormValues: {
        ...this.state.initialFormValues,
        misc: {
          ...this.state.initialFormValues.misc,
          type,
        },
      },
    });
  };

  onChangeMiscValue = (value) => {
    this.setState({
      initialFormValues: {
        ...this.state.initialFormValues,
        misc: {
          ...this.state.initialFormValues.misc,
          value,
        },
      },
    });
  };

  updateLineItemHeader = (e, directValue = '', lineItem) => {
    let value;
    if (e) {
      value = e.target.value;
    } else {
      value = directValue;
    }
    if (!value) {
      lineItem.header = `New Work Order Line ${lineItem.key}`;
    } else {
      lineItem.header = value;
    }
  };

  onAddLineItem = (withLabors = []) => {
    const key = this.state.lineItems.length + 1;
    const newLineItems = JSON.parse(JSON.stringify(this.state.lineItems));
    newLineItems.push({
      header: `New Work Order Line ${key}`,
      key: key,
      title: `New Work Order Line ${key}`,
      description: '',
      laborTotal: 0,
      partsTotal: 0,
      labours: withLabors,
      parts: [],
      services: [],
      miscellaneous: [],
      defects: [],
      expanded: true,
    });
    this.setState({
      lineItems: newLineItems,
    });
  };

  onChangeLineItemField = (lineKey, newVal, fieldName) => {
    const indexOfLineItem = this.state.lineItems.findIndex(
      (item) => item.key === lineKey
    );
    const lineItem = this.state.lineItems.find((item) => item.key === lineKey);
    const newLineItems = JSON.parse(JSON.stringify(this.state.lineItems));
    lineItem[fieldName] = newVal;
    newLineItems.splice(indexOfLineItem, 1, {
      ...lineItem,
    });
    this.setState({
      lineItems: newLineItems,
    });
  };

  onRemoveLineItem = (itemKey) => {
    const indexOfLineItem = this.state.lineItems.findIndex(
      (item) => item.key === itemKey
    );
    const newLineItems = JSON.parse(JSON.stringify(this.state.lineItems));
    newLineItems.splice(indexOfLineItem, 1);
    this.setState({
      lineItems: newLineItems,
    });
  };

  createWorkOrder = async (payload) => {
    try {
      const response = await WorkOrderStore.createWorkOrderOnCustomEndpoint({
        workOrder: {
          id_car: +payload.woSelectVehicle,
          asset_type: payload.woAssetType,
          id_shop: ShopStore.currentShop.id,
          status: payload.woStatus,
          type: payload.woRepairType,
          priority: payload.woRepairPriority,
          invoice_number: payload.woInvoiceNumber,
          invoice_number_customer: payload.invoiceNumberCustomer,
          assigned_to: +payload.woAssignedTo,
          created_by: +payload.woCreatedBy,
          tax: this.state.initialFormValues?.tax?.value || null,
          tax_type: this.state.initialFormValues?.tax?.type || null,
          misc: this.state.initialFormValues?.misc?.value || null,
          misc_type: this.state.initialFormValues?.misc?.type || null,
          warranty_savings: this.state.initialFormValues?.warrantySavings || null,
          migrate_source: 'PitStop',
          started_at: payload.woStartDate
            ? moment(payload.woStartDate).toDate()
            : null,
          done_at: payload.woCompletedDate
            ? moment(payload.woCompletedDate).toDate()
            : null,
          mileage: payload.mileage,
          engine_hours: payload.engineHours,
          location_code: payload.locationCode,
          vendor_id: payload.vendor_id,
          report_at: payload.woIssueReportDate
            ? moment(payload.woIssueReportDate).toDate()
            : null,
          additional_notes: payload.additionalNotes,
          driver_report_details: payload.driverReportDetails,
          purchase_order_id: payload.purchase_order_id,
          wo_files: payload.wo_files,
          costsTotal: this.costSummaryRef?.total || 0,
          partsTotal: this.costSummaryRef?.calculatedPartsTotal || 0,
          laborsTotal: this.costSummaryRef?.calculatedLaborsTotal || 0,
          costs_total_has_manual_override:
            payload.costs_total_has_manual_override,
          manual_costs_total: payload.manual_costs_total,
          labours_total_has_manual_override:
            payload.labours_total_has_manual_override,
          manual_labours_total: payload.manual_labours_total,
          parts_total_has_manual_override:
            payload.parts_total_has_manual_override,
          manual_parts_total: payload.manual_parts_total,
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  };

  updateWorkOrder = async (payload) => {
    try {
      const invoiceNumber = payload.wOid
        ? this.state.currentWorkOrder.invoice_number
        : payload.woInvoiceNumber;
      const response = await WorkOrderStore.updateWorkOrderOnCustomEndpoint({
        id: this.state.currentWorkOrder.id,
        id_car: +payload.woSelectVehicle,
        asset_type: payload.woAssetType,
        status: payload.woStatus,
        type: payload.woRepairType,
        priority: payload.woRepairPriority,
        invoice_number: invoiceNumber,
        invoice_number_customer: payload.invoiceNumberCustomer,
        w_ord_id: payload.woInvoiceNumber,
        assigned_to: +payload.woAssignedTo,
        created_by: +payload.woCreatedBy,
        tax: this.state.initialFormValues?.tax?.value || null,
        tax_type: this.state.initialFormValues?.tax?.type || null,
        misc: this.state.initialFormValues?.misc?.value || null,
        misc_type: this.state.initialFormValues?.misc?.type || null,
        warranty_savings: this.state.initialFormValues?.warrantySavings || null,
        started_at: payload.woStartDate
          ? moment(payload.woStartDate).toDate()
          : null,
        done_at: payload.woCompletedDate
          ? moment(payload.woCompletedDate).toDate()
          : null,
        mileage: payload.mileage,
        engine_hours: payload.engineHours,
        vendor_id: payload.vendor_id,
        report_at: payload.woIssueReportDate
          ? moment(payload.woIssueReportDate).toDate()
          : null,
        additional_notes: payload.additionalNotes,
        driver_report_details: payload.driverReportDetails,
        purchase_order_id: payload.purchase_order_id,
        wo_files: payload.wo_files,
        costsTotal: this.costSummaryRef?.total || 0,
        partsTotal: this.costSummaryRef?.calculatedPartsTotal || 0,
        laborsTotal: this.costSummaryRef?.calculatedLaborsTotal || 0,
        costs_total_has_manual_override:
          payload.costs_total_has_manual_override,
        manual_costs_total: payload.manual_costs_total,
        labours_total_has_manual_override:
          payload.labours_total_has_manual_override,
        manual_labours_total: payload.manual_labours_total,
        parts_total_has_manual_override:
          payload.parts_total_has_manual_override,
        manual_parts_total: payload.manual_parts_total,
        location_code: payload.locationCode,
      });

      // Check if we need to Resolve a DVIR
      if (
        payload.woStatus === 'resolved' &&
        payload.woRepairType === 'driver_identified' &&
        this.state.currentWorkOrder.work_order_dvirs.length > 0
      ) {
        if (!CurrentUserStore.user.email) {
          throw new Error('An user is required to resolve the DVIR');
        }

        const samsaraUsers = await CarStore.fetchSamsaraUsers(
          ShopStore.currentShop.id
        );
        // Select the current user email if it's in the list
        const currentUserEmail = CurrentUserStore.user.email;
        const samsaraUserResolving = samsaraUsers.find(
          (user) =>
            String(user.email).toLowerCase() ===
            String(currentUserEmail).toLowerCase()
        );

        let defects = [];
        let dvirId = this.state.currentWorkOrder.work_order_dvirs[0].dvir_id;
        this.state.currentWorkOrder.work_order_dvirs.forEach((dvirDefects) => {
          defects.push({ id: dvirDefects.defect_id.toString() });
        });

        if (dvirId && defects.length > 0) {
          const defectsResolved = await CarStore.resolveDVIRDefects({
            shopId: ShopStore.currentShop.id,
            dvirId: dvirId,
            defects: defects,
            mechanicNotes: 'Resolved by Pitstop',
            resolvedBySamsaraUserId:
              samsaraUserResolving?.id || currentUserEmail,
          });
          AppStore.addNotification(
            `${defectsResolved.length} defects resolved successfully`
          );
        }
      }
      return response;
    } catch (error) {
      throw error;
    }
  };

  onClickAddPurchaseOrder = () => {
    this.setState({ addPOModalVisible: true });
  };

  onAddPurchaseOrder = async (newPurchaseOrder) => {
    this.setState({
      addPOModalVisible: false,
    });
    if (newPurchaseOrder) {
      try {
        const newPO = await PurchaseOrderStore.savePurchaseOrder(
          newPurchaseOrder
        );
        AppStore.addSuccess('Purchase Order added successfully');
        await this.loadShopPurchaseOrders();
        // pre-select the purchase order
        this.setState({
          initialFormValues: {
            ...this.state.initialFormValues,
            purchaseOrder: newPO.id,
          },
        });
      } catch (error) {
        AppStore.addError('Error adding Purchase Order');
      }
    }
  };

  saveWorkOrder = async () => {
    const saveWorkOrderAfterValidation = async (payload) => {
      let workOrder;
      let woFileAndImages = {};
      if (
        this.state.activeTab === 'optional-information' ||
        !this.state.isMobileScreenSize // we know that if not mobile, the optional info is rendered
      ) {
        await this.uploadWorkOrderImages();
        await this.uploadWorkOrderFiles();
        const filteredWoImages = this.state.wo_images.filter(
          (url) =>
            !url.startsWith("https://work-orders-scan.s3.us-east-2.amazonaws.com/UFP-Workorder")
        );
        woFileAndImages.wo_files = {
          wo_images: filteredWoImages,
          wo_documents: this.state.wo_documents,
        };
      } else {
        // not in the optional-information tab, so if updating a work order, we need to keep the images and files
        if (this.state.currentWorkOrder) {
          woFileAndImages.wo_files = this.state.currentWorkOrder.wo_files;
        }
      }
      // if there are importedCostsTotal, add to the payload
      const importedCostsTotal = {};
      if (this.state.importedInformation) {
        const objImportedInfo = JSON.parse(this.state.importedInformation);
        if (
          objImportedInfo &&
          objImportedInfo['Labour Cost'] &&
          objImportedInfo['Labour Cost'] !== 'NOT FOUND'
        ) {
          importedCostsTotal.laborsTotal = parseFloat(
            objImportedInfo['Labour Cost']
          );
        }
        if (
          objImportedInfo &&
          objImportedInfo['Parts Cost'] &&
          objImportedInfo['Parts Cost'] !== 'NOT FOUND'
        ) {
          importedCostsTotal.partsTotal = parseFloat(
            objImportedInfo['Parts Cost']
          );
        }
        if (
          objImportedInfo &&
          objImportedInfo['Total Cost'] &&
          objImportedInfo['Total Cost'] !== 'NOT FOUND'
        ) {
          importedCostsTotal.costsTotal = parseFloat(
            objImportedInfo['Total Cost']
          );
        }
      }
      // always check if the work order has manual overrides
      const manualOverrides = {};

      if (this.state.costsTotalHasManualOverride) {
        manualOverrides.costs_total_has_manual_override = true;
        manualOverrides.manual_costs_total = this.state.manualOverrides.manualCostsTotal;
      } else {
        manualOverrides.costs_total_has_manual_override = false;
        manualOverrides.manual_costs_total = 0;
      }
      if (this.state.laboursTotalHasManualOverride) {
        manualOverrides.labours_total_has_manual_override = true;
        manualOverrides.manual_labours_total = this.state.manualOverrides.manualLaboursTotal;
      } else {
        manualOverrides.labours_total_has_manual_override = false;
        manualOverrides.manual_labours_total = 0;
      }
      if (this.state.partsTotalHasManualOverride) {
        manualOverrides.parts_total_has_manual_override = true;
        manualOverrides.manual_parts_total = this.state.manualOverrides.manualPartsTotal;
      } else {
        manualOverrides.parts_total_has_manual_override = false;
        manualOverrides.manual_parts_total = 0;
      }

      if (this.state.currentWorkOrder) {
        const modifiedPayload = {
          ...payload,
          ...woFileAndImages,
          ...importedCostsTotal,
          ...manualOverrides,
        };
        workOrder = await this.updateWorkOrder(modifiedPayload);
      } else {
        const modifiedPayload = {
          ...payload,
          ...woFileAndImages,
          ...importedCostsTotal,
          ...manualOverrides,
        };
        workOrder = await this.createWorkOrder(modifiedPayload);
      }

      // clear uploaded files and images
      this.setState({
        uploadedFiles: [],
        uploadedImages: [],
        wo_images: [],
        wo_documents: [],
      });

      return workOrder;
    };

    const workOrderFormProps = get(this.workOrderFormRef, 'props', null);
    const getPayloadFromCurrentWorkOrder = () => {
      return {
        woInvoiceNumber: this.state.currentWorkOrder.invoice_number,
        invoiceNumberCustomer: this.state.currentWorkOrder.invoice_number_customer,
        woCreatedBy: this.state.currentWorkOrder.created_by,
        woSelectVehicle: this.state.currentWorkOrder.id_car,
        woAssetType: this.state.currentWorkOrder.asset_type,
        woAssignedTo: this.state.currentWorkOrder.assigned_to,
        woStatus: this.state.currentWorkOrder.status,
        woRepairType: this.state.currentWorkOrder.type,
        woRepairPriority: this.state.currentWorkOrder.priority,
        // woIssueReportDate: this.state.currentWorkOrder.created_at,
        woStartDate:
          this.state.currentWorkOrder.woStartDate ||
          this.state.currentWorkOrder.started_at,
        woCompletedDate:
          this.state.currentWorkOrder.woCompletedDate ||
          this.state.currentWorkOrder.done_at,
        mileage:
          this.state.currentWorkOrder.mileage ||
          this.state.currentWorkOrder.mileage,
        engineHours:
          this.state.currentWorkOrder.engineHours ||
          this.state.currentWorkOrder.engine_hours,
        locationCode: this.state.currentWorkOrder.location_code,
        tax: {
          type: this.state.currentWorkOrder.tax_type,
          value: this.state.currentWorkOrder.tax,
        },
        misc: {
          type: this.state.currentWorkOrder.misc_type,
          value: this.state.currentWorkOrder.misc,
        },
        warrantySavings: this.state.currentWorkOrder.warranty_savings,
        vendor_id: this.state.currentWorkOrder.vendor_id,
        purchase_order_id: this.state.currentWorkOrder.purchase_order_id,
        additionalNotes: this.state.currentWorkOrder.additional_notes,
        driverReportDetails: this.state.currentWorkOrder.driver_report_details,
        woIssueReportDate:
          this.state.currentWorkOrder.woIssueReportDate ||
          this.state.currentWorkOrder.report_at,
        woSource: this.state.currentWorkOrder.migrate_source,
        rawData: this.state.currentWorkOrder.raw_data,
        laborsTotal: this.state.currentWorkOrder.laborsTotal,
        partsTotal: this.state.currentWorkOrder.partsTotal,
        // get the costsTotal from the summary component. It's already applying all the necessary logic
        costsTotal:
          this.costSummaryRef?.total ||
          0 ||
          this.state.currentWorkOrder.costsTotal,
        wOid: this.state.currentWorkOrder.w_ord_id,
      };
    };
    if (!workOrderFormProps && this.state.currentWorkOrder) {
      // the main form was not rendered, adapt the payload based on the currentWorkOrder
      const payload = getPayloadFromCurrentWorkOrder();
      return await saveWorkOrderAfterValidation(payload);
    }

    return new Promise((resolve, reject) => {
      workOrderFormProps.form.validateFields(async (err, payload) => {
        if (err) {
          this.setState({ savingWorkOrder: false });
          AppStore.removeLoading('Saving Work Order...');
          let friendlyMsg = '';
          const errorKeys = Object.keys(err);
          if (
            errorKeys &&
            errorKeys[0] &&
            err[errorKeys[0]] &&
            err[errorKeys[0]].errors &&
            err[errorKeys[0]].errors.length &&
            err[errorKeys[0]].errors[0].message
          ) {
            friendlyMsg = err[errorKeys[0]].errors[0].message;
          } else {
            friendlyMsg = 'Error on saving Work Order';
          }
          return notification.error({
            message: 'Oops!',
            key: 'orderSaveError',
            description: <Container>{friendlyMsg}</Container>,
          });
        }
        try {
          if (isEmpty(payload) && this.state.currentWorkOrder) {
            payload = getPayloadFromCurrentWorkOrder();
          } else {
            // check if the current tab is optional-information
            payload.wOid =
              this.state.initialFormValues.wOid ||
              this.state.currentWorkOrder?.w_ord_id;
            if (
              this.state.activeTab === 'optional-information' ||
              !this.state.isMobileScreenSize // we know that if not mobile, the optional info is rendered
            ) {
              // add to payload the optional information
              // the images and docs are being added in the saveWorkOrderAfterValidation
              payload.purchase_order_id = this.state.initialFormValues.purchase_order_id;
              payload.woIssueReportDate = this.state.initialFormValues.woIssueReportDate;
              payload.woStartDate = this.state.initialFormValues.woStartDate;
              payload.woCompletedDate = this.state.initialFormValues.woCompletedDate;
              payload.mileage = this.state.initialFormValues.mileage;
              payload.engineHours = this.state.initialFormValues.engineHours;
              payload.locationCode = this.state.initialFormValues.locationCode;
              payload.vendor_id = this.state.initialFormValues.vendor_id;
              payload.additionalNotes = this.state.initialFormValues.additionalNotes;

              // JSON
              payload.driverReportDetails = this.state.initialFormValues.driverReportDetails;

              // convert from moment to date
              if (payload.woStartDate) {
                payload.woStartDate = payload.woStartDate.toDate();
              }

              if (payload.woCompletedDate) {
                payload.woCompletedDate = payload.woCompletedDate.toDate();
              }

              if (payload.woIssueReportDate) {
                payload.woIssueReportDate = payload.woIssueReportDate.toDate();
              }
            }
          }
          const workOrder = await saveWorkOrderAfterValidation(payload);
          resolve(workOrder);
        } catch (error) {
          reject(error);
        }
      });
    });
  };

  formatWorkOrderLines = async (lines) => {
    return await Promise.all(
      lines.map(async (workOrderLine) => {
        const updatingData = {};
        if (workOrderLine.id) {
          updatingData.id = workOrderLine.id;
        }
        // remove empty parts (without a part selected)
        const parts = workOrderLine.parts.filter((p) => p.id_vehicle_part);
        // for now, we're going to force the selected labour if it's empty... To allow
        // the user to add a line item without any labor
        const labours = workOrderLine.labours.map((l) => {
          // check if id_labour is part of shop users
          // if not, set it to null
          const shopUsers = this.shopUsers;
          const isSelectedLaborAShopUser = shopUsers.find(
            (user) => user.id === parseInt(l.id_labour, 10)
          );
          if (isSelectedLaborAShopUser) {
            return l;
          }
          return {
            ...l,
            id_labour: this.state.currentWorkOrder.assigned_to,
          };
        });

        // if vmrs_sys_code_id is provided, find the vmrs record
        if (workOrderLine.vmrs_sys_code_id) {
          const response = await WorkOrderStore.getVmrsCodeById(
            workOrderLine.vmrs_sys_code_id
          );
          const vmrsSysCode = response?.data;
          if (vmrsSysCode) {
            updatingData.vmrs_sys_text = vmrsSysCode.text;
            updatingData.vmrs_sys_code = vmrsSysCode.sys_text;
          }
        }

        // if vmrs_asm_code_id is provided, find the vmrs record
        if (workOrderLine.vmrs_asm_code_id) {
          const response = await WorkOrderStore.getVmrsCodeById(
            workOrderLine.vmrs_asm_code_id
          );
          const vmrsAsmCode = response?.data;
          if (vmrsAsmCode) {
            updatingData.vmrs_asm_text = vmrsAsmCode.text;
            updatingData.vmrs_asm_code = vmrsAsmCode.asm_text;
          }
        }

        return {
          ...updatingData,
          title:
            workOrderLine.title || `New Work Order Line ${workOrderLine.key}`,
          description: workOrderLine.description,
          vmrs_sys_code_id: workOrderLine.vmrs_sys_code_id,
          vmrs_asm_code_id: workOrderLine.vmrs_asm_code_id,
          // always round the labour_cost to 2 decimal places
          labour_cost: parseFloat(parseFloat(workOrderLine.laborTotal).toFixed(2)),
          parts_cost: parseFloat(
            parseFloat(workOrderLine.partsTotal).toFixed(2)
          ),
          parts: parts.map((p) => ({
            ...p,
            id_vehicle_part: p.id_vehicle_part,
            amount: p.amount ? parseFloat(p.amount) : 0,
            originalAmount: p.originalAmount ? parseFloat(p.originalAmount) : 0,
            markup: p.markup ? parseFloat(p.markup) : 0,
            cost: p.cost ? parseFloat(parseFloat(p.cost).toFixed(2)) : 0,
          })),
          labours: labours.map((l) => ({
            ...l,
            id_labour: l.id_labour,
            hours: l.hours || 0,
            cost: parseFloat(l.cost || 0),
          })),
          services: workOrderLine.services.map((i) => ({
            ...i,
            issue: i.issue,
            id_vehicle_service: +i.id_vehicle_service,
          })),
          miscellaneous: workOrderLine.miscellaneous,
          defects: workOrderLine.defects || [],
        };
      })
    );
  };

  saveWorkOrderLines = async (workOrder) => {
    try {
      this.setState({ savingWorkOrderLines: true });

      const formattedWorkOrderLines = await this.formatWorkOrderLines(
        this.state.lineItems
      );

      const payloadToSave = {
        workOrderLines: formattedWorkOrderLines,
      };

      const response = await WorkOrderStore.saveWorkOrderLinesOnCustomEndpoint(
        +workOrder.id,
        payloadToSave
      );

      // update parts unit cost on shop
      // const lineItem = _.filter(this.state.lineItems, (lineItem) => {
      //   return lineItem.parts && lineItem.parts.length;
      // });
      // for (const lineItemWithParts of lineItem) {
      //   for (const part of lineItemWithParts.parts) {
      //     if (!part.id_vehicle_part) {
      //       continue;
      //     }
      //     await WorkOrderStore.updatePartOnCustomEndpoint({
      //       id: part.id_vehicle_part,
      //       cost: part.cost,
      //       markup: part.markup,
      //     });
      //   }
      // }

      // // update parts stock
      // const workOrderLines = _.filter(formattedWorkOrderLines, (lineItem) => {
      //   return lineItem.parts && lineItem.parts.length;
      // });
      // for (const lineItemWithParts of workOrderLines) {
      //   for (const part of lineItemWithParts.parts) {
      //     if (!part.id_vehicle_part) {
      //       continue;
      //     }
      //     await WorkOrderStore.updatePartStock(part);
      //   }
      // }

      return response;
    } catch (error) {
      throw error;
    } finally {
      this.setState({ savingWorkOrderLines: false });
    }
  };

  validateCosts = () => {
    const data = JSON.parse(this.state.importedInformation);
    const scannedPartsCost = data['Parts Cost'];
    const scannedLabourCost = data['Labour Cost'];

    // Parts
    const partsCostFromLineItems = _.sumBy(this.state.lineItems, 'partsTotal');
    if (partsCostFromLineItems !== scannedPartsCost) {
      const partsCostFromLineItemsToShow = _.isNumber(partsCostFromLineItems)
        ? partsCostFromLineItems.toFixed(2)
        : partsCostFromLineItems;
      const scannedPartsCostToShow = _.isNumber(scannedPartsCost)
        ? scannedPartsCost.toFixed(2)
        : scannedPartsCost;
      this.showWarningModal(
        'Parts Cost',
        partsCostFromLineItemsToShow,
        scannedPartsCostToShow
      );
      this.setState({ isPartsCostDiscrepancy: true, isPartsWarning: true });
    } else {
      this.setState({ isPartsCostDiscrepancy: false, isPartsWarning: false });
    }

    // Labours
    const laborCostFromLineItems = _.sumBy(this.state.lineItems, 'laborTotal');
    if (laborCostFromLineItems !== scannedLabourCost) {
      const laborCostFromLineItemsToShow = _.isNumber(laborCostFromLineItems)
        ? laborCostFromLineItems.toFixed(2)
        : laborCostFromLineItems;
      const scannedLabourCostToShow = _.isNumber(scannedLabourCost)
        ? scannedLabourCost.toFixed(2)
        : scannedLabourCost;
      this.showWarningModal(
        'Labor Cost',
        laborCostFromLineItemsToShow,
        scannedLabourCostToShow
      );
      this.setState({ isLaborCostDiscrepancy: true, isLabourWarning: true });
    } else {
      this.setState({ isLaborCostDiscrepancy: false, isLabourWarning: false });
    }
  };

  showWarningModal = (type, summedCost, scannedCost) => {
    Modal.warning({
      title: `${type} Discrepancy`,
      content: (
        <div>
          {type === 'Parts Cost' ? (
            <>
              The sum of <strong>Parts</strong> costs in line items is different
              from the
              <strong> Parts Cost </strong> value our AI has found. Please
              verify the accuracy of your line items as our AI has found the{' '}
              <strong>Parts Total for scanned items</strong> to be{' '}
              <strong> ${scannedCost} </strong>
              but the <strong>sum</strong> of parts costs from your{' '}
              <strong>service tasks</strong> is <strong> ${summedCost} </strong>
              .
            </>
          ) : (
            <>
              The sum of <strong>Labor</strong> costs in line items is different
              from the
              <strong> Labor Cost </strong> value our AI has found. Please
              verify the accuracy of your line items as our AI has found the{' '}
              <strong>Labor Total for scanned items</strong> to be{' '}
              <strong>${scannedCost} </strong>
              but the <strong>sum</strong> of labor costs from your{' '}
              <strong>service tasks</strong> is <strong>${summedCost} </strong>.
            </>
          )}
        </div>
      ),
      onOk: () => this.onModalClose(type),
    });
  };

  onModalClose = (type) => {
    if (type === 'Parts Cost') {
      this.setState({ isPartsWarning: false });
    } else if (type === 'Labor Cost') {
      this.setState({ isLabourWarning: false });
    }
  };

  onClickSave = async (nextBehavior = 'next-section') => {
    try {
      // save the work order first...
      const isNewWorkOrder = !this.state.currentWorkOrder;
      this.setState({ savingWorkOrder: true });
      AppStore.addLoading('Saving Work Order...');
      const newWorkOrder = await this.saveWorkOrder();
      AppStore.removeLoading('Saving Work Order...');
      if (!newWorkOrder) {
        return;
      }

      // save the newWorkOrder on current state
      this.setState({
        currentWorkOrder: {
          ...newWorkOrder,
          // those values are not being used in the first tab form
          // they are being used in the optional-information tab
          woStartDate: newWorkOrder.started_at
            ? moment(newWorkOrder.started_at)
            : null,
          woCompletedDate: newWorkOrder.done_at
            ? moment(newWorkOrder.done_at)
            : null,
          woIssueReportDate: newWorkOrder.report_at
            ? moment(newWorkOrder.report_at)
            : null,
          mileage: newWorkOrder.mileage,
          engineHours: newWorkOrder.engine_hours,
          locationCode: newWorkOrder.location_code,
          vendor_id: newWorkOrder.vendor_id,
          purchase_order_id: newWorkOrder.purchase_order_id,
          additionalNotes: newWorkOrder.additional_notes,
          driverReportDetails: newWorkOrder.driver_report_details
            ? {
              ...newWorkOrder.driver_report_details,
              timeOfEvent: newWorkOrder.driver_report_details.timeOfEvent
                ? moment(newWorkOrder.driver_report_details.timeOfEvent)
                : null,
            }
            : {
              driverName: '',
              driverNumber: '',
              location: '',
              timeOfEvent: null,
              weightOfVehicle: '',
              driverNotes: '',
              billingThrough: null,
            },
        },
        // lineItems: this.hydrateLineItems(newWorkOrder.work_order_lines),
      });

      // if there's no errors, save the work order lines
      AppStore.addLoading('Saving Work Order Lines...');
      await this.saveWorkOrderLines(newWorkOrder);
      AppStore.removeLoading('Saving Work Order Lines...');

      // WorkOrderStore.addWorkOrderToStore(newWorkOrder.id, newWorkOrder, true);
      PitstopTableCacheStore.deleteKey(`${this.shopId}-work-order-list`);
      WorkOrderStore.clearShopWorkOrdersCache(this.shopId);

      this.setState({ savingWorkOrder: false });

      notification.success({
        message: 'Work order saved',
        key: 'orderUpdated',
        description: (
          <Container>
            You can always click on the work order for more details and to make
            edits
          </Container>
        ),
      });

      if (this.queryAssetId) {
        return this.props.history.push(
          `/trailer/${this.queryAssetId}/work-orders`
        );
      }

      if (nextBehavior === 'exit') {
        return this.props.history.push('/work-order');
      }

      if (!this.state.isMobileScreenSize) {
        if (isNewWorkOrder) {
          return this.props.history.push(`/work-order/${newWorkOrder.id}/edit`);
        } else {
          return this.load();
        }
      }

      const currentSection = this.state.activeTab;
      const lastSection = this.orderedSections[this.orderedSections.length - 1];
      const isLastSection = currentSection === lastSection;
      const nextSection = this.orderedSections[
        this.orderedSections.indexOf(currentSection) + 1
      ];

      if (isNewWorkOrder && isLastSection) {
        return this.props.history.push(
          `/work-order/${newWorkOrder.id}/edit?tab=${lastSection}`
        );
      } else if (isNewWorkOrder) {
        return this.props.history.push(
          `/work-order/${newWorkOrder.id}/edit?tab=${nextSection}`
        );
      } else {
        if (!isLastSection) {
          this.setState({ activeTab: nextSection }, () => {
            // update the query param
            this.props.history.push({
              search: `?tab=${this.state.activeTab}`,
            });
          });
        }
        // just reload
        return this.load();
      }
    } catch (error) {
      let friendlyMsg =
        'Sorry, we had some problems to save the Work Order. Try again later';
      Logger.log(error.message);
      if (error && error.message) {
        try {
          const errorObj = JSON.parse(error.message);
          friendlyMsg = _.isArray(errorObj.message)
            ? errorObj.message[0]
            : errorObj.message;
        } catch (error) {
          Logger.log(error);
          friendlyMsg = error.message;
        }

        // customize if friendlyMsg is duplicate key value violates unique constraint
        if (
          friendlyMsg.includes(
            'duplicate key value violates unique constraint "invoice_number_unique"'
          )
        ) {
          friendlyMsg =
            'This work order already exists. Please, try again with a different Work Order Number.';
        }
      }
      notification.error({
        message: 'Oops!',
        key: 'orderSaveError',
        description: <Container>{friendlyMsg}</Container>,
      });
      AppStore.removeLoading('Saving Work Order...');
      AppStore.removeLoading('Saving Work Order Lines...');
      this.setState({ savingWorkOrder: false });
    }
  };

  uploadWorkOrderImages = async () => {
    const fileUrls = [];
    const uFiles = this.state.uploadedImages;

    for (const file of uFiles) {
      const fd = new FormData();
      fd.append('singleInputFileName', file.file);
      try {
        const data = await webServiceProvider.postFormData(
          'dashboard/v1/uploadimage',
          fd
        );
        if (data.fileUrl) {
          fileUrls.push(data.fileUrl);
        }
      } catch (e) {
        console.log(e);
      }
    }

    if (fileUrls.length > 0) {
      this.setState(
        (prevState) => ({
          wo_images: [...prevState.wo_images, ...fileUrls],
        }),
        () => {
          console.log('Updated wo_images:', this.state.wo_images);
        }
      );
    }
  };

  uploadWorkOrderFiles = async () => {
    const fileUrls = [];
    const uFiles = this.state.uploadedFiles;

    for (const file of uFiles) {
      const fd = new FormData();
      fd.append('singleInputFileName', file.file);
      try {
        const data = await webServiceProvider.postFormData(
          'dashboard/v1/uploadimage',
          fd
        );
        if (data.fileUrl) {
          fileUrls.push(data.fileUrl);
        }
      } catch (e) {
        console.log(e);
      }
    }

    if (fileUrls.length > 0) {
      this.setState(
        (prevState) => ({
          wo_documents: [...prevState.wo_documents, ...fileUrls],
        }),
        () => {
          console.log('Updated wo_documents:', this.state.wo_documents);
        }
      );
    }
  };

  // show modal to add new Part
  showModalToAddPart = (partName) => {
    this.setState({
      newPart: {
        name: '',
        description: partName,
        number: '',
        cost: 0,
      },
    });
    AppStore.openModals.set(ADD_PART_MODAL, true);
  };

  /**
   * Add a new part to the shop
   * @param {object} payload
   * @param {object} options
   * @return {Promise<object|null>} newPart
   */
  addNewPart = async (
    { description = '', number = '', cost = 0, addToInventory = false },
    { showSuccessAlert = true, hideModal = true, lineItemToAddPart = null }
  ) => {
    const getName = () => (number ? `${number} - ${description}` : description);
    const payload = {
      id_shop: ShopStore.currentShop.id,
      name: getName(),
    };
    // Logger.log('here a new part is attempting to get added');
    const newPart = await WorkOrderStore.createPartOnCustomEndpoint(payload);
    // update the new part with the description and number
    await WorkOrderStore.updatePartOnCustomEndpoint({
      id: newPart.id,
      description,
      number,
      cost,
      addToInventory,
    });
    if (showSuccessAlert) {
      notification.success({
        message: 'Part created',
        key: `newPartCreated${newPart.id}`,
        description: (
          <Container>The new part has been added to your account</Container>
        ),
      });
    }
    if (hideModal) {
      AppStore.openModals.set(ADD_PART_MODAL, false);
    }
    await this.loadShopParts();
    if (lineItemToAddPart) {
      // find the lineItem with the key (lineItemToAddPart)
      const lineItem = this.state.lineItems.find(
        (item) => item.key === lineItemToAddPart
      );
      if (lineItem) {
        this.onAddPart(lineItem.key, {
          id_vehicle_part: newPart.id,
          name: getName(),
          markup: 0,
          cost: parseFloat(cost),
        });
      }
    }

    return newPart;
  };

  onSubmitAddPartModal = async () => {
    try {
      this.setState({
        loadingSavingNewPart: true,
      });
      const {
        description,
        number,
        cost,
        addToInventory = false,
      } = this.state.newPart;
      await this.addNewPart(
        {
          description,
          number,
          cost,
          addToInventory,
        },
        {
          showSuccessAlert: true,
          hideModal: true,
          lineItemToAddPart: this.state.currentLinePartIsBeingAdded,
        }
      );
    } catch (error) {
      let friendlyMsg =
        'Sorry, we had some problems to save the Part. Try again later';
      console.log(error.message);
      if (error && error.message && JSON.parse(error.message)) {
        const errorObj = JSON.parse(error.message);
        friendlyMsg = _.isArray(errorObj.message)
          ? errorObj.message[0]
          : errorObj.message;
      }
      notification.error({
        message: 'Oops!',
        key: 'orderSaveError',
        description: <Container>{friendlyMsg}</Container>,
      });
    } finally {
      this.setState({
        loadingSavingNewPart: false,
      });
    }
  };

  onAddNewPart = async (partName = '', lineKey = null) => {
    this.setState({
      currentLinePartIsBeingAdded: lineKey,
    });
    this.showModalToAddPart(partName);
  };

  onUpdateLaborItem = (lineItemKey, laborKey, newVal, whatChanged) => {
    const lineItem = this.state.lineItems.find(
      (item) => item.key === lineItemKey
    );
    const labour = lineItem.labours.find((labour) => labour.key === laborKey);
    _.merge(labour, {
      ...labour,
      [whatChanged]: newVal,
    });
    // refresh cost
    const indexOfLineItem = this.state.lineItems.findIndex(
      (item) => item.key === lineItemKey
    );
    const newLineItems = JSON.parse(JSON.stringify(this.state.lineItems));
    lineItem.laborTotal = _.sumBy(lineItem.labours, (labour) => {
      return parseFloat(labour.cost || 0) * parseFloat(labour.hours || 0);
    });
    newLineItems.splice(indexOfLineItem, 1, {
      ...lineItem,
    });
    this.setState({
      lineItems: newLineItems,
    });
  };

  onUpdatePartItem = (lineItemKey, partKey, newVal, changedField) => {
    const costToChange = {};
    if (changedField === 'id_vehicle_part') {
      const shopPart = this.state.shopParts.find((part) => part.id === newVal);
      if (shopPart && shopPart.cost !== null && shopPart.cost !== undefined) {
        const partCost = parseFloat(shopPart.cost);
        costToChange.cost = partCost;
      }
    }
    const lineItem = this.state.lineItems.find(
      (item) => item.key === lineItemKey
    );
    const part = lineItem.parts.find((part) => part.key === partKey);
    _.merge(part, {
      ...part,
      [changedField]: newVal,
      ...costToChange,
    });
    // refresh cost
    const indexOfLineItem = this.state.lineItems.findIndex(
      (item) => item.key === lineItemKey
    );
    const newLineItems = JSON.parse(JSON.stringify(this.state.lineItems));
    // refresh cost
    lineItem.partsTotal = _.sumBy(lineItem.parts, (part) => {
      const cost = part.cost ? parseFloat(part.cost) : 0;
      const markup = part.markup ? parseFloat(part.markup) : 0;
      const amount = part.amount ? parseFloat(part.amount) : 0;
      return (cost + (cost * markup) / 100) * amount;
    });
    newLineItems.splice(indexOfLineItem, 1, {
      ...lineItem,
    });
    this.setState({
      lineItems: newLineItems,
    });
  };

  onUpdateMiscellaneousItem = (lineItemKey, miscKey, newVal, changedField) => {
    this.setState({
      lineItems: this.state.lineItems.map((item) => {
        if (item.key === lineItemKey) {
          return {
            ...item,
            miscellaneous: item.miscellaneous.map((misc) => {
              if (misc.key === miscKey) {
                return {
                  ...misc,
                  [changedField]: newVal,
                };
              }
              return misc;
            }),
          };
        }
        return item;
      }),
    });
  };

  onUpdateDefectItem = (lineItemKey, defectKey, newVal, changedField) => {
    this.setState({
      lineItems: this.state.lineItems.map((item) => {
        if (item.key === lineItemKey) {
          return {
            ...item,
            defects: item.defects.map((defect) => {
              if (defect.key === defectKey) {
                const dvirId = this.state.assetDefects.find(
                  assetDefect => assetDefect.id === newVal
                )?.dvirId || null;
                return {
                  ...defect,
                  [changedField]: newVal,
                  dvirId,
                };
              }
              return defect;
            }),
          };
        }
        return item;
      }),
    });
  };

  onSelectSystemCode = (lineItemKey, systemCode) => {
    // update the current vmrs_sys_code_id on the lineItem
    const lineItem = this.state.lineItems.find(
      (item) => item.key === lineItemKey
    );
    lineItem.vmrs_sys_code_id = systemCode;
    lineItem.vmrs_sys_code = null;
    lineItem.vmrs_sys_text = null;
    this.setState({
      lineItems: this.state.lineItems,
    });
  };

  onSelectAsmCode = (lineItemKey, asmCode) => {
    // update the current vmrs_asm_code_id on the lineItem
    const lineItem = this.state.lineItems.find(
      (item) => item.key === lineItemKey
    );
    lineItem.vmrs_asm_code_id = asmCode;
    lineItem.vmrs_asm_code = null;
    lineItem.vmrs_asm_text = null;
    this.setState({
      lineItems: this.state.lineItems,
    });
  };

  onUpdateServiceItem = (lineItemKey, serviceKey, newVal) => {
    // Logger.log('onUpdateServiceItem is being run');
    const lineItem = this.state.lineItems.find(
      (item) => item.key === lineItemKey
    );
    const service = lineItem.services.find(
      (service) => service.key === serviceKey
    );
    _.merge(service, newVal);
  };

  onChangeCollapseItems = (activeLineItemsKeys) => {
    const { lineItems } = this.state;
    lineItems.forEach((lineItem) => {
      if (activeLineItemsKeys.includes(`${lineItem.key}`)) {
        lineItem.expanded = true;
      } else {
        lineItem.expanded = false;
      }
    });
    this.forceUpdate();
  };

  onGetCarDetails = (carDetails) => {
    this.setState({
      carDetails,
    }, () => {
      this.loadAssetDVIRs(carDetails);
    });
  };

  handleExportClick = async (type) => {
    const workOrderId = this.props.match.params.id;

    switch (type) {
      case 'print':
        try {
          AppStore.addLoading('Getting Work Order Report');
          await WorkOrderStore.printWorkOrder(workOrderId);
        } catch (error) {
          console.error(error);
          AppStore.addError(
            'Unknown error to generate the Work Order. Please try again later'
          );
        } finally {
          AppStore.removeLoading('Getting Work Order Report');
        }
        break;
      case 'download':
        try {
          AppStore.addLoading('Getting Work Order Report');
          await WorkOrderStore.downloadWorkOrder(workOrderId);
        } catch (error) {
          console.error(error);
          AppStore.addError(
            'Unknown error to generate the Work Order. Please try again later'
          );
        } finally {
          AppStore.removeLoading('Getting Work Order Report');
        }
        break;
      default:
        break;
    }
  };

  handleFileUpload = (file, additionalData) => {
    console.log('Before update wo_documents:', this.state.wo_documents);
    this.setState(
      (prevState) => {
        let updatedDocuments = prevState.uploadedFiles;
        let woDocumentsUpdated = prevState.wo_documents;

        if (additionalData === 'removed') {
          updatedDocuments = prevState.uploadedFiles.filter(
            (item) => item.file.uid !== file.uid || item.file.name !== file.name
          );
          woDocumentsUpdated = prevState.wo_documents.filter(
            (documentUrl) => documentUrl !== file.url
          );
        } else {
          updatedDocuments = [
            ...prevState.uploadedFiles,
            { file, additionalData },
          ];
        }

        return {
          uploadedFiles: updatedDocuments,
          wo_documents: woDocumentsUpdated,
        };
      },
      () => {
        console.log(
          'Updated state after file upload:',
          this.state.uploadedFiles
        );
        console.log('Updated wo_documents:', this.state.wo_documents);
      }
    );

    // console.log('Document uploaded:', file);
    // console.log('Additional data:', additionalData);
    // console.log('State data:', this.state.uploadedFiles);
  };

  handleImageUpload = (file, additionalData) => {
    console.log('Before update wo_images:', this.state.wo_images);
    this.setState(
      (prevState) => {
        let updatedImages = prevState.uploadedImages;
        let woImagesUpdated = prevState.wo_images;

        if (additionalData === 'removed') {
          // Remove file from uploadedImages based on uid and name
          updatedImages = prevState.uploadedImages.filter(
            (item) => item.file.uid !== file.uid || item.file.name !== file.name
          );
          woImagesUpdated = prevState.wo_images.filter(
            (imageUrl) => imageUrl !== file.url
          );
        } else {
          updatedImages = [
            ...prevState.uploadedImages,
            { file, additionalData },
          ];
        }

        return {
          uploadedImages: updatedImages,
          wo_images: woImagesUpdated,
        };
      },
      () => {
        console.log(
          'Updated state after image upload:',
          this.state.uploadedImages
        );
        console.log('Updated wo_images:', this.state.wo_images);
      }
    );

    // console.log('Image uploaded:', file);
    // console.log('Additional data:', additionalData);
  };

  addNewShopUser = async ({ name = '', email = '', role = 'dealership' }) => {
    if (
      !ShopStore.currentShop.id ||
      ShopStore.currentShop.id === -1 ||
      ShopStore.currentShop.id === '-1'
    ) {
      // bounce
      return;
    }
    try {
      const userAdded = await UserStore.addUserToShop({
        shopId: ShopStore.currentShop.id,
        firstName: name,
        role: role,
        shopRole: 'fleetManager',
        email: email,
        username: email,
        password: Math.random()
          .toString(36)
          .slice(-8),
        isSendCredentialsByPhone: false,
      });
      return userAdded;
    } catch (error) {
      Logger.error(error);
      let message = error.message;
      if (
        String(error?.message).indexOf('is already used') &&
        error?.message.includes('@username.pitstop')
      ) {
        // get the first part of the email
        const email = error?.message
          .split(' ')
          .find((part) => part.includes('@username.pitstop'));
        const username = email.split('@')[0];
        message = `The username ${username} is already in use. Please try another one.`;
      }
      AppStore.addError(message);
    }
  };

  loadWorkOrderWithExtractedInformation = async (data, options = {}) => {
    const { stringify = true, fillWorkOrderNumber = false } = options;
    // Logger.log('we are now attempting to fill in line items with json');
    this.setState({
      importedInformation: stringify ? JSON.stringify(data, null, 4) : data,
    });
    const clearStr = (str) => deburr(kebabCase(str)).toLowerCase();
    const isFound = (str) => str && clearStr(str) !== 'not-found';
    try {
      // Logger.log('loading work order supposedly');
      AppStore.addLoading('Loading Work Order');
      const extractedInitialFormValues = {
        woRepairType: 'driver_identified',
        woStatus: 'resolved',
      };
      // wo #
      if (isFound(data['Work Order Number']) && fillWorkOrderNumber) {
        extractedInitialFormValues.woInvoiceNumber = data['Work Order Number'];
      }
      // start date
      if (isFound(data['Service Date'])) {
        if (moment(data['Service Date'], 'MM/DD/YYYY').isValid()) {
          extractedInitialFormValues.woStartDate = moment(
            data['Service Date'],
            'MM/DD/YYYY'
          );
        } else if (moment(data['Service Date'], 'YYYY-MM-DD').isValid()) {
          extractedInitialFormValues.woStartDate = moment(
            data['Service Date'],
            'YYYY-MM-DD'
          );
        }
      }
      // vehicle
      if (isFound(data['Vehicle Identifier'])) {
        const { result: vehicle } = await CarStore.getShopCarByVin(
          ShopStore.currentShop.id,
          data['Vehicle Identifier']
        );
        if (vehicle.length) {
          extractedInitialFormValues.woSelectVehicle = vehicle[0].id;
        } else {
          extractedInitialFormValues.extractedVin = data['Vehicle Identifier'];
        }
      }
      // Tax
      if (isFound(data['Tax'])) {
        extractedInitialFormValues.tax = {
          type: 'absolute',
          value: parseFloat(data['Tax']),
        };
      }
      // Service Line Items
      const lineItems = [];
      if (
        isArray(data['Service Line Items']) &&
        !isEmpty(data['Service Line Items'])
      ) {
        const serviceLineItems = data['Service Line Items'];
        // Check if version 2 scan
        if (first(serviceLineItems).hasOwnProperty('parts_labor_or_other')) {
          // get parts
          const parts = serviceLineItems.filter(
            (item) =>
              String(item['parts_labor_or_other'])
                .trim()
                .toLowerCase() === 'parts'
          );
          // get labours
          const labours = serviceLineItems.filter(
            (item) =>
              String(item['parts_labor_or_other'])
                .trim()
                .toLowerCase() === 'labor'
          );
          // get others
          const others = serviceLineItems.filter(
            (item) =>
              String(item['parts_labor_or_other'])
                .trim()
                .toLowerCase() === 'other'
          );
          const lineItem = {
            key: lineItems.length + 1,
            title: `Service Line Item ${lineItems.length + 1}`,
            description: '',
            labour_cost: 0,
            parts_cost: 0,
            parts: [],
            labours: [],
            miscellaneous: [],
            defects: [],
          };
          for (const part of parts) {
            const partName = part['description'];
            const partCost = isFound(part['unit_cost'])
              ? parseFloat(parseFloat(part['unit_cost']).toFixed(2))
              : 0;
            const partNumber = isFound(part['part_number'])
              ? part['part_number']
              : '';
            const partMarkup = isFound(part['markup'])
              ? parseFloat(part['markup'])
              : 0;
            const partQuantity = isFound(part['quantity'])
              ? parseFloat(part['quantity'])
              : 0;
            // search that part name in the shop/subshop parts
            let foundPart = this.state.shopParts.find((shopPart) => {
              return (
                clearStr(shopPart.name) ===
                clearStr(`${partNumber} - ${partName}`)
              );
            });
            if (!foundPart) {
              // if not found, create a new part
              foundPart = await this.addNewPart(
                {
                  description: partName,
                  number: partNumber || '',
                  cost: partCost,
                  addToInventory: false,
                },
                {
                  showSuccessAlert: false,
                  hideModal: false,
                  lineItemToAddPart: null,
                }
              );
            }
            lineItem.parts.push({
              key: lineItem.parts.length + 1,
              id_vehicle_part: foundPart.id,
              name: foundPart.name,
              amount: partQuantity,
              originalAmount: partQuantity,
              markup: partMarkup,
              cost: partCost,
            });
          }

          for (const labour of labours) {
            const labourName = labour['technician_name'];
            const labourCost = isFound(labour['unit_cost'])
              ? parseFloat(labour['unit_cost'])
              : 0;
            const labourQuantity = isFound(labour['quantity'])
              ? parseFloat(labour['quantity'])
              : 0;
            const labourDescription = labour['description'];

            lineItem.labours.push({
              key: lineItem.labours.length + 1,
              id_labour: null,
              name: labourName,
              description: labourDescription,
              hours: labourQuantity,
              cost: labourCost,
            });
          }

          for (const other of others) {
            const otherDescription = other['description'];
            const otherCost = isFound(other['unit_cost'])
              ? parseFloat(other['unit_cost'])
              : 0;
            const otherQuantity = isFound(other['quantity'])
              ? parseFloat(other['quantity'])
              : 0;
            lineItem.miscellaneous.push({
              key: lineItem.miscellaneous.length + 1,
              description: otherDescription,
              quantity: otherQuantity,
              unit_cost: otherCost,
            });
          }

          // refresh parts total cost
          lineItem.parts_cost = _.sumBy(lineItem.parts, (part) => {
            const cost = part.cost ? parseFloat(part.cost) : 0;
            const markup = part.markup ? parseFloat(part.markup) : 0;
            const amount = part.amount ? parseFloat(part.amount) : 0;
            return (cost + (cost * markup) / 100) * amount;
          });

          // refresh labour total cost
          lineItem.labour_cost = _.sumBy(lineItem.labours, (labour) => {
            return parseFloat(labour.cost || 0) * parseFloat(labour.hours || 0);
          });
          // always round the labour_cost to 2 decimal places
          lineItem.labour_cost = parseFloat(
            parseFloat(lineItem.labour_cost).toFixed(2)
          );

          // refresh miscellaneous total cost
          lineItem.miscellaneous_cost = _.sumBy(
            lineItem.miscellaneous,
            (misc) => {
              return (
                parseFloat(misc.unit_cost || 0) * parseFloat(misc.quantity || 0)
              );
            }
          );

          // push the lineItem before handling parts
          lineItems.push(lineItem);
        } else {
          // Version 1 Scan
          for (const serviceLineItem of serviceLineItems) {
            if (isFound(serviceLineItem['name'])) {
              const lineItem = {
                key: lineItems.length + 1,
                title: serviceLineItem['name'],
                description: serviceLineItem['description'] || '',
                labour_cost: 0,
                parts_cost: 0,
                parts: [],
                labours: [],
              };
              // check if has parts and add it too
              if (
                serviceLineItem['parts'] &&
                isArray(serviceLineItem['parts'])
              ) {
                // for each part, search that part based on it's name. If not found, create one and get the data back.
                for (const part of serviceLineItem['parts']) {
                  if (!isFound(part['name'])) {
                    // if the part name is not found, skip it
                    continue;
                  }
                  const partName = part['name'];
                  const partCost = isFound(part['unit_cost'])
                    ? parseFloat(part['unit_cost'])
                    : 0;
                  const partNumber = isFound(part['part_number'])
                    ? part['part_number']
                    : '';
                  const partMarkup = isFound(part['mark_up'])
                    ? parseFloat(part['mark_up'])
                    : 0;
                  const partQuantity = isFound(part['quantity'])
                    ? parseFloat(part['quantity'])
                    : 0;
                  // search that part name in the shop/subshop parts
                  let foundPart = this.state.shopParts.find((shopPart) => {
                    return (
                      clearStr(shopPart.name) ===
                      clearStr(`${partNumber} - ${partName}`)
                    );
                  });
                  if (!foundPart) {
                    // if not found, create a new part
                    foundPart = await this.addNewPart(
                      {
                        description: partName,
                        number: partNumber || '',
                        cost: partCost,
                        addToInventory: false,
                      },
                      {
                        showSuccessAlert: false,
                        hideModal: false,
                        lineItemToAddPart: null,
                      }
                    );
                  }
                  lineItem.parts.push({
                    key: lineItem.parts.length + 1,
                    id_vehicle_part: foundPart.id,
                    name: foundPart.name,
                    amount: partQuantity,
                    originalAmount: partQuantity,
                    markup: partMarkup,
                    cost: partCost,
                  });
                }
              }

              // refresh parts total cost
              lineItem.parts_cost = _.sumBy(lineItem.parts, (part) => {
                const cost = part.cost ? parseFloat(part.cost) : 0;
                const markup = part.markup ? parseFloat(part.markup) : 0;
                const amount = part.amount ? parseFloat(part.amount) : 0;
                return (cost + (cost * markup) / 100) * amount;
              });

              // push the lineItem before handling parts
              lineItems.push(lineItem);
            }
          }
        }
      }
      const hydratedLineItems = this.hydrateLineItems(lineItems);
      this.setState(
        {
          lineItems: hydratedLineItems,
          initialFormValues: {
            ...this.state.initialFormValues,
            ...extractedInitialFormValues,
          },
        }
      );
    } catch (error) {
      Logger.error(error);
    } finally {
      AppStore.removeLoading('Loading Work Order');
    }
  };

  handleWorkOrderUpload = async (fileList) => {
    if (!fileList.length) {
      return;
    }
    try {
      this.setState({
        uploading: true,
        isErrorWorkOrderUpload: false,
        progress: 0,
      });
      const firstFile = fileList[0].originFileObj;
      // pass the first file to the ImageUploadContainer, only if it's an image
      if (
        firstFile.type.includes('image') &&
        this.imageUploaderContainerRef &&
        this.imageUploaderContainerRef.handlePreview
      ) {
        this.imageUploaderContainerRef.handlePreview(firstFile);
      } else if (
        this.documentUploaderContainerRef &&
        this.documentUploaderContainerRef.setFileList
      ) {
        this.documentUploaderContainerRef.setFileList(fileList);
        this.handleFileUpload(firstFile, 'added');
      }

      // convert first file to base64
      const reader = new FileReader();
      reader.readAsDataURL(firstFile);
      reader.onload = async (e) => {
        const base64 = e.target.result;

        this.setState({ scannedWorkOrderPreview: base64 });
        // scroll to the top
        this.scrollToTopAndSaveOriginalMediaPreviewData();

        const withoutHeaders = base64.split(',')[1];
        // upload the base64 to the server
        try {
          const woOcrAbortController = new AbortController();
          this.setState({
            isLoadingWorkOrderUpload: true,
            progress: 75,
            workOrderImgOcrSignal: woOcrAbortController,
          });
          const data = await WorkOrderStore.workOrderImgOcr(
            {
              base64: withoutHeaders,
              shopId: ShopStore.currentShop.id,
              fileType: firstFile.type,
            },
            woOcrAbortController.signal
          );
          this.setState(
            { isUploadWorkOrderModalVisible: false, progress: 90 },
            () => {
              this.loadWorkOrderWithExtractedInformation(data, {
                stringify: true,
                fillWorkOrderNumber: false,
              });
            }
          );
          this.setState({ uploading: false, progress: 100 });
        } catch (error) {
          if (error.name === 'AbortError') {
            Logger.log('OCR request aborted');
            this.setState({
              uploading: false,
              progress: 0,
              isErrorWorkOrderUpload: false,
            });
          } else {
            Logger.log('Error:', error);
            this.setState({
              uploading: false,
              progress: 0,
              isErrorWorkOrderUpload: true,
            });
          }
        } finally {
          this.setState({
            isLoadingWorkOrderUpload: false,
            uploading: false,
            progress: 0,
          });
        }
      };
    } catch (error) {
      Logger.error(error);
    }
  };

  get vendorOptions () {
    return this.state.shopVendors.map((user, index) => (
      <Select.Option key={index} value={user.id}>
        {user.name}
      </Select.Option>
    ));
  }

  setInitialFormValue = (key, value) => {
    this.setState({
      initialFormValues: {
        ...this.state.initialFormValues,
        [key]: value,
      },
    });
  };

  setCurrentWorkOrderValue = (key, value) => {
    this.setState({
      currentWorkOrder: {
        ...this.state.currentWorkOrder,
        [key]: value,
      },
    });
  };

  getRandomKey = (arr = []) => {
    const newKey = _.random(10000);
    if (arr.some((a) => a.key === newKey)) {
      return this.getRandomKey(arr);
    }
    return newKey;
  };

  onAddLabor = (lineItemKey) => {
    const currentUserSettings = CurrentUserStore.user.settings;
    this.setState({
      lineItems: this.state.lineItems.map((lineItem) => {
        if (lineItem.key === lineItemKey) {
          lineItem.labours.push({
            key: this.getRandomKey(lineItem.labours),
            id_labour: null,
            hours: 0,
            cost: currentUserSettings?.laborRate || 0,
          });
        }
        return lineItem;
      }),
    });
  };

  onRemoveLabor = (laborKey, lineItemKey) => {
    this.setState({
      lineItems: this.state.lineItems.map((lineItem) => {
        if (lineItem.key === lineItemKey) {
          lineItem.labours = lineItem.labours.filter(
            (labour) => labour.key !== laborKey
          );
        }
        return lineItem;
      }),
    });
  };

  onRemovePart = (partKey, lineItemKey) => {
    this.setState({
      lineItems: this.state.lineItems.map((lineItem) => {
        if (lineItem.key === lineItemKey) {
          lineItem.parts = lineItem.parts.filter(
            (part) => part.key !== partKey
          );
        }
        return lineItem;
      }),
    });
  };

  onRemoveMiscellaneous = (miscKey, lineItemKey) => {
    this.setState({
      lineItems: this.state.lineItems.map((lineItem) => {
        if (lineItem.key === lineItemKey) {
          lineItem.miscellaneous = lineItem.miscellaneous.filter(
            (misc) => misc.key !== miscKey
          );
        }
        return lineItem;
      }),
    });
  };

  onRemoveDefect = (defectKey, lineItemKey) => {
    this.setState({
      lineItems: this.state.lineItems.map((lineItem) => {
        if (lineItem.key === lineItemKey) {
          lineItem.defects = lineItem.defects.filter(
            (defect) => defect.key !== defectKey
          );
        }
        return lineItem;
      }),
    });
  };

  onRemoveIssue = (issueKey, lineItemKey) => {
    this.setState({
      lineItems: this.state.lineItems.map((lineItem) => {
        if (lineItem.key === lineItemKey) {
          lineItem.services = lineItem.services.filter(
            (issue) => issue.key !== issueKey
          );
        }
        return lineItem;
      }),
    });
  };

  onAddPart = (lineItemKey, existingPart = {}) => {
    this.setState({
      lineItems: this.state.lineItems.map((lineItem) => {
        if (lineItem.key === lineItemKey) {
          const partKey = this.getRandomKey(lineItem.parts);
          lineItem.parts.push({
            key: partKey,
            id_vehicle_part: null,
            name: `Part ${partKey}`,
            amount: 1,
            originalAmount: 1,
            markup: 0,
            cost: 0,
            ...existingPart,
          });
        }
        return lineItem;
      }),
    });
  };

  onAddIssue = (lineItemKey) => {
    this.setState({
      lineItems: this.state.lineItems.map((lineItem) => {
        if (lineItem.key === lineItemKey) {
          const issueKey = this.getRandomKey(lineItem.services);
          lineItem.services.push({
            key: issueKey,
            id_vehicle_service: '',
            name: `Issue ${issueKey}`,
          });
        }
        return lineItem;
      }),
    });
  };

  onAddMiscellaneous = (lineItemKey) => {
    this.setState({
      lineItems: this.state.lineItems.map((lineItem) => {
        if (lineItem.key === lineItemKey) {
          const miscKey = this.getRandomKey(lineItem.miscellaneous);
          lineItem.miscellaneous.push({
            key: miscKey,
            description: '',
            quantity: 1,
            unit_cost: 0,
          });
        }
        return lineItem;
      }),
    });
  };

  onAddDefect = (lineItemKey) => {
    this.setState({
      lineItems: this.state.lineItems.map((lineItem) => {
        if (lineItem.key === lineItemKey) {
          if (!lineItem.defects) {
            lineItem.defects = [];
          }
          const defectKey = this.getRandomKey(lineItem.defects);
          lineItem.defects.push({
            key: defectKey,
            id_defect: null,
            name: `Defect ${defectKey}`,
            isResolved: false,
            comment: '',
            dvirId: null,
          });
        }
        return lineItem;
      }),
    });
  };

  onChangeSummaryTotal = (value) => {
    this.setState({
      costsTotalHasManualOverride: true,
      manualOverrides: {
        ...this.state.manualOverrides,
        manualCostsTotal: value,
      },
    });
  };

  onResetCostsTotalOverride = () => {
    this.setState({
      costsTotalHasManualOverride: false,
      manualOverrides: {
        ...this.state.manualOverrides,
        manualCostsTotal: 0,
      },
    });
  };

  onChangeLabourTotal = (value) => {
    this.setState({
      laboursTotalHasManualOverride: true,
      manualOverrides: {
        ...this.state.manualOverrides,
        manualLaboursTotal: value,
      },
    });
  };

  onResetLabourTotalOverride = () => {
    this.setState({
      laboursTotalHasManualOverride: false,
      manualOverrides: {
        ...this.state.manualOverrides,
        manualLaboursTotal: 0,
      },
    });
  };

  onChangePartsTotal = (value) => {
    this.setState({
      partsTotalHasManualOverride: true,
      manualOverrides: {
        ...this.state.manualOverrides,
        manualPartsTotal: value,
      },
    });
  };

  onChangeWarrantySavings = (value) => {
    this.setState({
      initialFormValues: {
        ...this.state.initialFormValues,
        warrantySavings: value,
      },
    });
  };

  onResetPartsTotalOverride = () => {
    this.setState({
      partsTotalHasManualOverride: false,
      manualOverrides: {
        ...this.state.manualOverrides,
        manualPartsTotal: 0,
      },
    });
  };

  onClickImage = async (imageUrlOrBase64) => {
    // let auxImageUrl = imageUrlOrBase64;
    // // check if image is actually a URL
    // if (!(imageUrlOrBase64.includes('http://') || imageUrlOrBase64.includes('https://'))) {
    //   // upload the image to the server to get the URL
    //   AppStore.addLoading('Uploading Image');
    //   // convert imageUrlOrBase64 to a file
    //   const base64ToFile = (base64, filename) => {
    //     const arr = base64.split(',');
    //     const mime = arr[0].match(/:(.*?);/)[1];
    //     const bstr = atob(arr[1]);
    //     let n = bstr.length;
    //     const u8arr = new Uint8Array(n);
    //     while (n--) {
    //       u8arr[n] = bstr.charCodeAt(n);
    //     }
    //     return new File([u8arr], filename, { type: mime });
    //   };
    //   const fileExtension = imageUrlOrBase64.split(';')[0].split('/')[1];
    //   const randomName = Math.random().toString(36).substring(7);
    //   const file = base64ToFile(imageUrlOrBase64, `preview_${randomName}.${fileExtension}`);
    //   const fd = new FormData();
    //   fd.append('singleInputFileName', file);
    //   const data = await webServiceProvider.postFormData(
    //     'dashboard/v1/uploadShopImage?shopId=' + ShopStore.currentShop.id,
    //     fd
    //   );
    //   auxImageUrl = data.fileUrl;
    //   AppStore.removeLoading('Uploading Image');
    // }
    // check if isMobile
    if (this.state.isMobileScreenSize) {
      this.setState(
        {
          currentImagePreviewSrc: imageUrlOrBase64,
          isImagePreviewModalVisible: true,
        },
        this.scrollToTopAndSaveOriginalMediaPreviewData
      );
    } else {
      // show on split screen
      this.setState(
        {
          scannedWorkOrderPreview: imageUrlOrBase64,
        },
        this.scrollToTopAndSaveOriginalMediaPreviewData
      );
    }
  };

  render () {
    const {
      showUploadButton,
      isUploadWorkOrderModalVisible,
      uploading,
      progress,
    } = this.state;

    /**
     * Please check: https://www.notion.so/Dashboard-Work-Order-Context-Management-63b5031b223541f9b1b3c2f0c6527f53?pvs=4
     * For more information about the context values and how it's being used
     */
    const contextValue = {
      state: this.state,
      shopUsers: this.shopUsers,
      shopParts: this.state.shopParts,
      shopVendors: this.state.shopVendors,
      currentCarIssues: this.state.currentCarIssues,
      onCarSelect: this.onCarSelect,
      onChangeAssignedTo: this.onChangeAssignedTo,
      onChangePurchaseOrder: this.onChangePurchaseOrder,
      onGetCarDetails: this.onGetCarDetails,
      vendorOptions: this.vendorOptions,
      updateWorkOrder: this.updateWorkOrder,
      onClickAddPurchaseOrder: this.onClickAddPurchaseOrder,
      onLocationSelect: this.onLocationSelect,
      // line items
      onSelectAsmCode: this.onSelectAsmCode,
      onSelectSystemCode: this.onSelectSystemCode,
      onAddLineItem: this.onAddLineItem,
      updateLineItemHeader: this.updateLineItemHeader,
      onRemoveLineItem: this.onRemoveLineItem,
      onChangeLineItemField: this.onChangeLineItemField,
      onAddNewPart: this.onAddNewPart,
      onUpdateLaborItem: this.onUpdateLaborItem,
      onUpdatePartItem: this.onUpdatePartItem,
      onUpdateMiscellaneousItem: this.onUpdateMiscellaneousItem,
      onUpdateDefectItem: this.onUpdateDefectItem,
      onUpdateServiceItem: this.onUpdateServiceItem,
      onChangeCollapseItems: this.onChangeCollapseItems,
      onAddLabor: this.onAddLabor,
      onAddPart: this.onAddPart,
      onAddIssue: this.onAddIssue,
      onAddMiscellaneous: this.onAddMiscellaneous,
      onAddDefect: this.onAddDefect,
      onRemoveLabor: this.onRemoveLabor,
      onRemovePart: this.onRemovePart,
      onRemoveMiscellaneous: this.onRemoveMiscellaneous,
      onRemoveDefect: this.onRemoveDefect,
      onRemoveIssue: this.onRemoveIssue,
      // optional informations
      setImageUploaderContainerRef: this.setImageUploaderContainerRef,
      setDocumentUploaderContainerRef: this.setDocumentUploaderContainerRef,
      imageUploaderContainerRef: this.imageUploaderContainerRef,
      documentUploaderContainerRef: this.documentUploaderContainerRef,
      handleImageUpload: this.handleImageUpload,
      handleFileUpload: this.handleFileUpload,
      setInitialFormValue: this.setInitialFormValue,
      setCurrentWorkOrderValue: this.setCurrentWorkOrderValue,
      // summary
      onChangeTaxType: this.onChangeTaxType,
      onChangeTaxValue: this.onChangeTaxValue,
      onChangeMiscType: this.onChangeMiscType,
      onChangeMiscValue: this.onChangeMiscValue,
      onChangeSummaryTotal: this.onChangeSummaryTotal,
      onChangeLabourTotal: this.onChangeLabourTotal,
      onChangePartsTotal: this.onChangePartsTotal,
      onChangeWarrantySavings: this.onChangeWarrantySavings,
      onResetCostsTotalOverride: this.onResetCostsTotalOverride,
      onResetLabourTotalOverride: this.onResetLabourTotalOverride,
      onResetPartsTotalOverride: this.onResetPartsTotalOverride,
      // images and documents
      onClickImage: this.onClickImage,
    };

    return this.state.loadingWorkOrder || this.state.loadingInitial ? (
      <LoadingContainer>
        <Spin tip="Loading..." />
      </LoadingContainer>
    ) : (
      <WorkOrderProvider value={contextValue}>
        <DealershipChooser />
        <Row gutter={[8, 8]}>
          <Col xs={24} sm={24}>
            <FlexRow>
              <h1
                style={{
                  flex: 1,
                }}
                className="page-title"
              >
                {this.state.title} Work Order
              </h1>
              <>
                {showUploadButton && (
                  <UploadButton
                    icon="upload"
                    onClick={() => {
                      this.setState(
                        {
                          isUploadWorkOrderModalVisible: true,
                          isErrorWorkOrderUpload: false,
                        },
                        () => {
                          this.forceUpdateTabs(); // Force re-render after setting the state
                        }
                      );
                    }}
                  >
                    Scan Work Order
                  </UploadButton>
                )}
                {this.state.currentWorkOrder && (
                  <>
                    <Button
                      type="primary"
                      ghost
                      onClick={() => this.handleExportClick('download')}
                      className="small-screen"
                      style={{ marginLeft: 10 }}
                    >
                      <Icon type="download" />
                      Download
                    </Button>
                    <Button
                      type="primary"
                      ghost
                      onClick={() => this.handleExportClick('print')}
                      className="small-screen"
                      style={{ marginLeft: 10 }}
                    >
                      <Icon type="printer" />
                      Print
                    </Button>
                  </>
                )}
              </>
            </FlexRow>
            <UploadImageModal
              isVisible={isUploadWorkOrderModalVisible}
              isLoading={this.state.isLoadingWorkOrderUpload}
              isError={this.state.isErrorWorkOrderUpload}
              onUpload={this.handleWorkOrderUpload}
              onCancel={() => {
                // stop the upload if it's still uploading
                if (this.state.isLoadingWorkOrderUpload) {
                  if (this.state.workOrderImgOcrSignal) {
                    this.state.workOrderImgOcrSignal.abort();
                  }
                  this.setState({
                    isLoadingWorkOrderUpload: false,
                    isErrorWorkOrderUpload: false,
                    progress: 0,
                    workOrderImgOcrSignal: null,
                  });
                }
                this.setState({
                  isUploadWorkOrderModalVisible: false,
                });
              }}
              uploading={uploading}
              progress={progress}
            />
          </Col>
        </Row>

        {/* If mobile, render the tabs structure */}
        {this.state.isMobileScreenSize && (
          <Row className="limit-size-tablets">
            <Col span={24}>
              <Tabs
                className="scrollable-tabs"
                defaultActiveKey={this.state.activeTab}
                onChange={(key) => {
                  this.setState({ activeTab: key }, () => {
                    this.props.history.push({
                      search: `?tab=${this.state.activeTab}`,
                    });
                  });
                }}
              >
                <Tabs.TabPane tab="Work Order Details" key="work-order-details">
                  <Row type="flex" justify="center">
                    <Col sm={24} lg={18}>
                      <WorkOrderForm
                        childRef={(ref) => (this.workOrderFormRef = ref)}
                      />
                    </Col>
                  </Row>
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab="Work Order Lines"
                  key="work-order-line-items"
                  disabled={!this.state.currentWorkOrder}
                >
                  <Row type="flex" justify="center">
                    <Col sm={24} lg={18}>
                      <WorkOrderLineItems />
                    </Col>
                  </Row>
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab="Optional Information"
                  key="optional-information"
                  disabled={!this.state.currentWorkOrder}
                >
                  <Row type="flex" justify="center">
                    <Col className="pb-15" sm={24} lg={18}>
                      <WorkOrderOptionalInformation />
                    </Col>
                  </Row>
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab="Summary"
                  key="summary"
                  disabled={!this.state.currentWorkOrder}
                >
                  <Row type="flex" justify="center">
                    <Col sm={24} lg={18}>
                      <WorkOrderCostSummary
                        refs={(ref) => (this.costSummaryRef = ref)}
                      />
                    </Col>
                  </Row>
                </Tabs.TabPane>
              </Tabs>
            </Col>
          </Row>
        )}

        {/* If desktop, render everything in one page */}
        {!this.state.isMobileScreenSize && (
          <Row>
            {this.state.importedInformation ||
              this.state.scannedWorkOrderPreview ? (
              <Col span={10}>
                {this.state.importedInformation && (
                  <>
                    <NotificationBanner
                      message="Double-check all data fields are correct after scanning the work order to avoid errors and ensure smooth operations."
                      onClose={() => { }}
                    />
                    <Collapse
                      style={{ marginBottom: 15, marginTop: 15 }}
                      defaultActiveKey={[]}
                      onChange={() => { }}
                    >
                      <Panel header="Imported Information" key="1">
                        <ImportedInformation>
                          <pre>{this.state.importedInformation}</pre>
                        </ImportedInformation>
                      </Panel>
                    </Collapse>
                  </>
                )}
                {this.state.scannedWorkOrderPreview && (
                  <MediaPreview media={this.state.scannedWorkOrderPreview} />
                )}
              </Col>
            ) : null}
            <Col
              span={
                this.state.importedInformation ||
                  this.state.scannedWorkOrderPreview
                  ? 14
                  : 24
              }
            >
              <Row type="flex" justify="center">
                <Col sm={24} lg={18}>
                  <WorkOrderForm
                    childRef={(ref) => (this.workOrderFormRef = ref)}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center">
                <Col sm={24} lg={18}>
                  <WorkOrderLineItems />
                </Col>
              </Row>
              <Row type="flex" justify="center">
                <Col className="pb-15" sm={24} lg={18}>
                  <WorkOrderOptionalInformation />
                </Col>
              </Row>
              <Row type="flex" justify="center">
                <Col sm={24} lg={18}>
                  <WorkOrderCostSummary
                    isEdit={this.state.isEdit}
                    ref={(ref) => (this.costSummaryRef = ref)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        <Row gutter={[8, 8]}>
          <Col span={18} offset={3}>
            <ActionButtonsWrapperFlexEnd>
              <PitstopButton type="link" onClick={this.goBack}>
                Cancel
              </PitstopButton>
              {// if activeTab is not summary, show Save & Exit
                this.state.activeTab !== 'summary' && (
                  <PitstopButton
                    onClick={() => this.onClickSave('exit')}
                    type="link"
                    disabled={this.state.isDisabled}
                  >
                    Save & Exit
                  </PitstopButton>
                )}
              <PitstopButton
                loading={this.state.savingWorkOrder}
                onClick={this.onClickSave}
                disabled={this.state.isDisabled}
              >
                {this.state.activeTab === 'summary' ||
                  !this.state.isMobileScreenSize
                  ? 'Finish'
                  : 'Next Section'}
              </PitstopButton>
            </ActionButtonsWrapperFlexEnd>
          </Col>
        </Row>

        <StyledModal
          id={ADD_PART_MODAL}
          title={'Add New Part'}
          width={640}
          okText="Save"
          footer={
            <>
              <Button
                key="back"
                onClick={() => {
                  AppStore.openModals.set(ADD_PART_MODAL, false);
                }}
              >
                Cancel
              </Button>
              <Button
                key="submit"
                type="primary"
                onClick={() => {
                  this.onSubmitAddPartModal();
                }}
                loading={this.state.loadingSavingNewPart}
              >
                Save
              </Button>
            </>
          }
        >
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Text strong>Part Number</Text>
              <Input
                style={{ width: '100%' }}
                value={this.state.newPart.number}
                onChange={(e) => {
                  this.setState({
                    newPart: {
                      ...this.state.newPart,
                      number: e.target.value,
                    },
                  });
                }}
              />
              {String(this.state.newPart.number).trim() &&
                this.state.shopParts.find(
                  (shopPart) =>
                    String(shopPart.number).trim() ===
                    String(this.state.newPart.number).trim()
                ) && (
                  <Typography.Text type="danger">
                    A part with this number already exists in your inventory. To
                    avoid duplicates, increase its stock count instead of adding
                    a new part.{' '}
                    <Link
                      to={`/parts/${this.state.shopParts.find(
                        (shopPart) =>
                          String(shopPart.number).trim() ===
                          String(this.state.newPart.number).trim()
                      ).id
                        }`}
                    >
                      Go to Existing Part
                    </Link>
                  </Typography.Text>
                )}
            </Col>
            <Col span={24}>
              <Text strong>Description</Text>
              <TextArea
                id="description"
                rows={4}
                placeholder="Add Description"
                value={this.state.newPart.description}
                onChange={(e) => {
                  this.setState({
                    newPart: {
                      ...this.state.newPart,
                      description: e.target.value,
                    },
                  });
                }}
              />
            </Col>
            <Col span={24}>
              <Text strong>Unit Cost:</Text>
              <InputNumber
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                style={{ width: '100%' }}
                value={this.state.newPart.cost}
                onChange={(value) => {
                  this.setState({
                    newPart: {
                      ...this.state.newPart,
                      cost: value,
                    },
                  });
                }}
                inputMode="tel"
              />
            </Col>
            <Col span={24}>
              <Checkbox
                style={{
                  marginTop: '10px',
                }}
                onChange={(e) => {
                  this.setState({
                    newPart: {
                      ...this.state.newPart,
                      addToInventory: e.target.checked,
                    },
                  });
                }}
              >
                Check box to add part to Parts List inventory. Leave unchecked
                to exclude from inventory
              </Checkbox>
            </Col>
          </Row>
        </StyledModal>

        <ImagePreviewModal
          visible={this.state.isImagePreviewModalVisible}
          footer={null}
          onCancel={() => {
            this.setState({
              isImagePreviewModalVisible: false,
              currentImagePreviewSrc: '',
            });
          }}
        >
          <MediaPreview media={this.state.currentImagePreviewSrc} />
        </ImagePreviewModal>

        <PurchaseOrderAddModal
          visible={this.state.addPOModalVisible}
          onSave={this.onAddPurchaseOrder}
          onCancel={() => {
            this.setState({
              addPOModalVisible: false,
            });
          }}
        />
      </WorkOrderProvider>
    );
  }
}

decorate(WorkOrderDetails, {
  shopId: observable,
  shopUsers: computed,
});

export default withRouter(observer(WorkOrderDetails));
