import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { observe } from 'mobx';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment-timezone';
import _ from 'lodash';
import {
  Spin,
  Card,
  Row,
  Col,
  Form,
  Empty,
  Input,
  Button,
  Collapse,
  Typography,
  Tag,
} from 'antd';
import TimeSelector from 'containers/AnalyticsView/TimeSelector';
import PidsGraph from 'containers/AnalyticsView/PidsGraph';
import ReportHistoryTable from 'containers/CarProfile/AlgorithmInsights/ReportHistoryTable';
import AlgorithmIcon from 'containers/CarProfile/AlgorithmInsights/AlgorithmIcon';
import MarkAsCompleteBtn from './MarkAsCompleteBtn';
import {
  fetchSensorData,
  fetchCarDetails,
  generateFallbackTimeRange,
} from './sensorDataUtils';
import { calcMileage } from 'helpers/unitCalculations';
import {
  AppStore,
  CarStore,
  IssueStore,
  CurrentUserStore,
  ShopStore,
} from 'stores';

const { Panel } = Collapse;

// The VMRS_TO_ICON_MAP provides a clear mapping between VMRS text values and their corresponding icon names.
const VMRS_TO_ICON_MAP = {
  'CRANKING SYSTEM': 'Battery',
  'CHARGING SYSTEM': 'Battery',
  'BRAKE SYSTEM': 'Brakes',
  'EXHAUST SYSTEM': 'DEF System',
  'AIR INTAKE SYSTEM': 'Sensor Health',
};

// Function to determine icon name based on VMRS text
const getIconNameFromVmrs = (vmrsText) => {
  const iconName = VMRS_TO_ICON_MAP[vmrsText];
  if (!iconName) {
    console.log(
      `No direct icon mapping found for VMRS text: ${vmrsText}, defaulting to Sensor Health`
    );
    return 'Sensor Health';
  }
  return iconName;
};

// Styled Components

const PriorityTag = styled(Tag)`
  margin: 0;
  padding: 4px 10px; /* Increase padding for size */
  border-radius: 10px; /* Rounder corners for emphasis */
  font-size: 13px; /* Larger font size */
  font-weight: 1000; /* Bold text for prominence */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase; /* Optional: Make the text uppercase */
  letter-spacing: 1px; /* Optional: Add some spacing for readability */
  background-color: ${(props) =>
    props.color || '#f0f0f0'}; /* Use dynamic or fallback background */
  color: #ffffff; /* White text for contrast */
  border: none; /* Remove the border for a cleaner look */
`;
const PageWrapper = styled.div`
  padding: 24px;
  background: #f5f6f8;
  min-height: 100vh;
`;
const CardStyles = `
  &.ant-card {
    border-radius: 24px;
    margin-bottom: 12px;
  }

  .ant-card-body {
    border-radius: 12px;
    padding: 24px;
  }
`;
const InfoCard = styled(Card)`
  ${CardStyles}

  .content-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  border-radius: 36px;
  background: white;
  margin-bottom: 24px;
  padding: 32px;

  .content-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
  }

  .left-section {
    display: flex;
    align-items: flex-start;
    gap: 48px;
  }

  .icon-container {
    width: 120px;
    height: 120px;
    // background: #eef2ff;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;

    /* Adjust this for spacing to the right */
    margin-right: 100px;

    /* Use transform to nudge the icon itself */
    transform: translate(20px, 20px);

    &::before {
      border: none;
    }

    /* Стили для ::after */
    &::after {
      border: none;
    }
  }

  .system-name {
    font-size: 16px;
    font-weight: 500;
    color: #111827;
    text-align: center;
    margin-bottom: 4px;
  }

  .new-tag {
    color: #ef4444;
    font-size: 14px;
    text-align: center;
    display: block;
  }

  .score-label {
    color: #6b7280;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
  }

  .score-value {
    font-size: 48px;
    font-weight: 600;
    line-height: 1;
    color: #111827;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
  }

  .vmrs-label {
    color: #6b7280;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
  }

  .vmrs-value {
    font-size: 24px;
    font-weight: 600;
    color: #111827;
    margin-bottom: 2px;
  }

  .vmrs-code {
    color: #6b7280;
    font-size: 16px;
  }

  .divider {
    height: 1px;
    background: #e5e7eb;
    margin: 0 -32px 24px;
  }

  .metadata-section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 48px;
  }

  .metadata-label {
    color: #6b7280;
    font-size: 14px;
    margin-bottom: 4px;
  }

  .metadata-value {
    font-size: 14px;
    color: #111827;

    &.link {
      color: #2563eb;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const MainCard = styled(Card)`
  ${CardStyles}

  .header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  &.ant-card {
    border-radius: 24px;
    margin-bottom: 12px;
  }

  .ant-card-body {
    border-radius: 24px;
    padding: 24px;
  }

  .header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  .title {
    font-size: 24px;
    font-weight: 600;
    color: #111827;
    margin: 0;
  }

  .priority-tag {
    background: #fee2e2;
    color: #b91c1c;
    border: none;
    padding: 8px 16px;
    border-radius: 8px;
    font-size: 14px;
  }

  .description {
    color: #6b7280;
    font-size: 16px;
    line-height: 1.5;
  }
`;

const ScoreExplanation = styled.div`
  color: #6b7280;
  font-size: 14px;
  line-height: 1.5;
  padding-left: 16px;
`;

// Main Component Implementation
class IssueProfilePage extends Component {
  static propTypes = {
    history: PropTypes.object,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }),
    form: PropTypes.object,
  };

  state = {
    startTime: null,
    endTime: null,
    sensorData: null,
    notes: '',
    isFetchingData: false,
    timeSelectorVisible: false,
  };

  async componentDidMount() {
    AppStore.setSelectedSidebarKey('/issues');
    await this.fetchIssueData();
    await this.initializeSensorDataFromIssue();
  }

  async initializeSensorDataFromIssue() {
    const issue = this.getIssue();
    if (issue?.car?.id) {
      const { startTime, endTime } = generateFallbackTimeRange();
      console.log('Initializing sensor data for:', {
        carId: issue.car.id,
        startTime,
        endTime,
      });
      await this.initializeSensorData(issue, startTime, endTime);
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { startTime, endTime, isFetchingData } = this.state;
    const issue = this.getIssue();

    if (
      !isFetchingData &&
      issue?.car?.id &&
      (startTime !== prevState.startTime || endTime !== prevState.endTime)
    ) {
      console.log('Time range changed, fetching new sensor data');
      await this.fetchSensorDataWithState(issue.car.id, startTime, endTime);
    }
  }

  async initializeSensorData(issue, startTime, endTime) {
    if (!issue?.car?.id) {
      console.error('Cannot initialize sensor data: Missing car ID');
      return;
    }

    if (!startTime || !endTime) {
      console.error('Cannot initialize sensor data: Invalid time range');
      return;
    }

    this.setState({ startTime, endTime, isFetchingData: true }, async () => {
      try {
        await this.fetchSensorDataWithState(issue.car.id, startTime, endTime);
      } catch (error) {
        console.error('Failed to initialize sensor data:', error);
        this.setState({ isFetchingData: false });
      }
    });
  }

  async fetchSensorDataWithState(carId, startTime, endTime) {
    this.setState({ isFetchingData: true });

    try {
      await fetchSensorData(carId, startTime, endTime, (stateUpdates) => {
        console.log('Sensor data fetched successfully');
        this.setState({ ...stateUpdates, isFetchingData: false });
      });
    } catch (error) {
      console.error('Error fetching sensor data:', error);
      this.setState({ isFetchingData: false });
    }
  }

  async fetchIssueData() {
    const issueId = this.getIssueId();

    try {
      if (!IssueStore.data.has(issueId) || !this.getIssue()?.loaded) {
        await IssueStore.getIssueById(issueId);
      }

      const issue = this.getIssue();
      if (!issue) {
        throw new Error('Failed to fetch issue data');
      }

      this.setState({ notes: issue.notes || '' });

      if (issue.car?.id) {
        await fetchCarDetails(issue.car.id);
      }
    } catch (error) {
      console.error('Error fetching issue data:', error);
    }
  }

  getIssueId = () => Number(this.props.match.params.id);
  getIssue = () => IssueStore.data.get(this.getIssueId());

  renderSensorData() {
    const { sensorData, isFetchingData } = this.state;

    if (isFetchingData) {
      return <Spin tip="Loading sensor data..." />;
    }

    if (!sensorData) {
      return (
        <Empty description="No sensor data available for the selected time range." />
      );
    }

    const issue = this.getIssue();
    return (
      <PidsGraph
        carId={issue.car.id}
        shopName={issue.car.shopName}
        data={sensorData}
        startTime={this.state.startTime}
        endTime={this.state.endTime}
      />
    );
  }

  renderMileageWithUnit() {
    const { car } = this.getIssue();
    const mileage = car.mileageTotal || 0;
    const unit = CurrentUserStore.user.settings.unit || 'miles';

    const calculatedMileage = calcMileage(mileage, unit);
    const formattedMileage = parseFloat(calculatedMileage).toFixed(0);

    return `${formattedMileage} ${unit}`;
  }

  saveNotes = async () => {
    const issue = this.getIssue();
    if (issue) {
      try {
        await IssueStore.updateIssue(issue.id, { notes: this.state.notes });
        console.log('Notes saved successfully');
      } catch (error) {
        console.error('Error saving notes:', error);
      }
    }
  };

  render() {
    const issue = this.getIssue();
    const { notes } = this.state;

    if (!issue) {
      return <Spin tip="Loading issue data..." />;
    }

    const iconName = getIconNameFromVmrs(issue.vmrs?.text);

    return (
      <PageWrapper>
        <MainCard>
          <div className="header-row">
            <Typography.Title level={2} className="title">
              {issue.getName()}
            </Typography.Title>
            <PriorityTag color={IssueStore.data.get(issue.id).priorityTagColor}>
              {IssueStore.data.get(issue.id).priorityCategory ||
                'Unknown Priority'}
            </PriorityTag>
          </div>
          <Typography.Paragraph className="description">
            {issue.report?.explanation || 'No explanation available.'}
          </Typography.Paragraph>
        </MainCard>

        <InfoCard>
          <div className="content-wrapper">
            <div className="left-section">
              {/* Icon Section */}
              <div className="icon-container custom-icon-container">
                <div className="icon-square">
                  <AlgorithmIcon
                    iconName={iconName}
                    selected={true}
                    status={issue.status}
                    selectFunction={() => {}}
                  />
                </div>
              </div>

              {/* Score and VMRS Section */}
              <div>
                <div className="score-label">Score</div>
                <div className="score-value">
                  {issue.report?.score || 'N/A'}
                  {issue.report?.score_explanation && (
                    <ScoreExplanation>
                      {issue.report?.score_explanation}
                    </ScoreExplanation>
                  )}
                </div>
                <div className="vmrs-label">VMRS</div>
                <div className="vmrs-value">{issue.vmrs?.text || 'N/A'}</div>
                <div className="vmrs-code">({issue.vmrs?.sys || 'N/A'})</div>
              </div>
            </div>

            <MarkAsCompleteBtn issue={issue} />
          </div>

          <div className="divider" />

          <div className="metadata-section">
            <div>
              <div className="metadata-label">Vehicle</div>
              <Link to={`/car/${issue.car.id}`} className="metadata-value link">
                {issue.car.name || issue.car.vin}
              </Link>
            </div>
            <div>
              <div className="metadata-label">Mileage</div>
              <div className="metadata-value">
                {this.renderMileageWithUnit()}
              </div>
            </div>
            <div>
              <div className="metadata-label">Report Date</div>
              <div className="metadata-value">
                {moment(issue.reportDate).format('YYYY-MM-DD HH:mm:ss')}
              </div>
            </div>
          </div>
        </InfoCard>

        <MainCard>
          <Typography.Title level={3} style={{ marginBottom: '24px' }}>
            Sensor Data
          </Typography.Title>
          <Collapse defaultActiveKey={[]} expandIconPosition="right">
            <Panel header="Adjust Time Range" key="1">
              <TimeSelector
                resetBtn={() => {
                  const fallback = generateFallbackTimeRange();
                  this.setState({
                    startTime: fallback.startTime,
                    endTime: fallback.endTime,
                    sensorData: null,
                  });
                }}
                searchBtn={(startTime, endTime) => {
                  if (issue?.car?.id) {
                    this.fetchSensorDataWithState(
                      issue.car.id,
                      startTime,
                      endTime
                    );
                  }
                }}
              />
            </Panel>
          </Collapse>
          {this.renderSensorData()}
        </MainCard>

        {/*
        Re[prt History Table - from the pitstop insights table should be added in here to show score historyies of the algorithm
        <MainCard>
          <Typography.Title level={3} style={{ marginBottom: '24px' }}>
            Report History
          </Typography.Title>
          <ReportHistoryTable
            history={issue.history || []}
            item={issue.type}
          />
        </MainCard>
        */}

        <MainCard>
          <Typography.Title level={3} style={{ marginBottom: '24px' }}>
            Notes
          </Typography.Title>
          <Form>
            <Form.Item>
              <Input.TextArea
                value={notes}
                onChange={(e) => this.setState({ notes: e.target.value })}
                autoSize={{ minRows: 3, maxRows: 5 }}
                placeholder="Add your notes here..."
              />
            </Form.Item>
            <Button type="primary" onClick={this.saveNotes}>
              Save Notes
            </Button>
          </Form>
        </MainCard>
      </PageWrapper>
    );
  }
}

export default withRouter(observer(IssueProfilePage));
