import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Layout, Menu, Icon, Drawer } from 'antd';

import {
  DollarOutlined,
  LineChartOutlined,
  BarChartOutlined,
  HeatMapOutlined,
  BulbOutlined,
  HeartOutlined,
} from '@ant-design/icons';

import { customFleets } from 'shared';

import { AppStore, CurrentUserStore, ShopStore } from 'stores';

import { computed, decorate } from 'mobx';

const { Sider } = Layout;
const { SubMenu } = Menu;

class Sidebar extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    collapsed: PropTypes.bool.isRequired,
    toggleSideBar: PropTypes.func,
  };

  state = {
    submenu: ['2', '7', '10', '14'],
    width: 0,
  };

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  onClose = () => {
    this.props.toggleSideBar();
  };

  get adminDealerSidebar() {
    const isLongBeachShopAndAdminOrCustomLongBeachUsers =
      ShopStore.currentShop.id === 1085 &&
      (CurrentUserStore.user.role === 'admin' ||
        [13512, 11719, 13620].includes(CurrentUserStore.user.id));
    if (isLongBeachShopAndAdminOrCustomLongBeachUsers) {
      return [
        <SubMenu
          key="Analytics_Fleet_Performance"
          title={
            <span>
              <Icon type="dashboard" />
              Fleet Analytics
            </span>
          }
        >
          <Menu.Item
            key="/highpriorityvehicles"
            onClick={() => this.props.history.push('/highpriorityvehicles')}
          >
            <span>
              <Icon type="car" />
              High Priority Vehicles
            </span>
          </Menu.Item>
          <Menu.Item
            key="/downtimecost"
            onClick={() => this.props.history.push('/downtimecost')}
          >
            <DollarOutlined />
            Downtime Cost Analytics
          </Menu.Item>
          <Menu.Item
            key="/algorithminsights"
            onClick={() => this.props.history.push('/algorithminsights')}
          >
            <BulbOutlined />
            Algorithm Insights
          </Menu.Item>
          <Menu.Item
            key="/fleethealth"
            onClick={() => this.props.history.push('/fleethealth')}
          >
            <HeartOutlined />
            Fleet Ranking
          </Menu.Item>
          <Menu.Item
            key="/idlingfuelreports"
            onClick={() => this.props.history.push('/idlingfuelreports')}
          >
            <HeatMapOutlined />
            Idling Reports
          </Menu.Item>
          <Menu.Item
            key="/alertsaccuracy"
            onClick={() => this.props.history.push('/alertsaccuracy')}
          >
            <BarChartOutlined />
            Alerts Accuracy
          </Menu.Item>
          <Menu.Item
            key="/shopperformance"
            onClick={() => this.props.history.push('/shopperformance')}
          >
            <LineChartOutlined />
            Shop Performance
          </Menu.Item>
        </SubMenu>,
        <SubMenu
          key="Smart_Maintenance"
          title={
            <span>
              <Icon type="radius-setting" />
              Smart Maintenance
            </span>
          }
        >
          <Menu.Item
            key="/vehicles"
            onClick={() => this.props.history.push('/vehicles')}
          >
            <Icon type="car" />
            Vehicles
          </Menu.Item>
          ,
          <Menu.Item
            key="/issues"
            onClick={() => this.props.history.push('/issues')}
          >
            <span>
              <Icon type="warning" />
              Issue Management
            </span>
          </Menu.Item>
          <Menu.Item
            key="/appointments"
            onClick={() => this.props.history.push('/appointments')}
          >
            <LineChartOutlined />
            Smart PM Schedule
          </Menu.Item>
          <Menu.Item
            key="/location"
            onClick={() => this.props.history.push('/location')}
          >
            <span>
              <Icon type="pushpin" />
              Fleet Live Location
            </span>
          </Menu.Item>
          <Menu.Item
            key="/service-interval"
            onClick={() => this.props.history.push('/service-interval')}
          >
            <Icon type="tool" />
            Service Intervals
          </Menu.Item>
        </SubMenu>,
        <SubMenu
          key="Settings"
          title={
            <span>
              <Icon type="setting" />
              Settings
            </span>
          }
        >
          <Menu.Item
            key="/contacts"
            onClick={() => this.props.history.push('/contacts')}
          >
            <span>
              <Icon type="contacts" />
              Vendor Management
            </span>
          </Menu.Item>
          <Menu.Item
            key="/users"
            onClick={() => this.props.history.push('/user-management')}
          >
            <span>
              <Icon type="user" />
              Users & Notifications
            </span>
          </Menu.Item>
        </SubMenu>,
      ];
    }

    const isCardinalCouriersShop = customFleets.cardinalCourier.includes(ShopStore.currentShop.id);
    const isAmerTestShop = ShopStore.currentShop.id === 1326;

    const getFleetAnalytics = () => {
      const defaultItems = [
        <Menu.Item
          key="/highpriorityvehicles"
          onClick={() => this.props.history.push('/highpriorityvehicles')}
        >
          <Icon type="car" />
          High Priority Vehicles
        </Menu.Item>,
      ];

      defaultItems.push(
        <Menu.Item
          key="/location"
          onClick={() => this.props.history.push('/location')}
        >
          <Icon type="pushpin" />
          Fleet Live Location
        </Menu.Item>
      );
      defaultItems.push(
        <Menu.Item
          key="/fleet-reports"
          onClick={() => this.props.history.push('/fleet-reports')}
        >
          <Icon type="bar-chart" />
          Fleet Reports
        </Menu.Item>
      );
      defaultItems.push(
        <Menu.Item
          key="/part-reports"
          onClick={() => this.props.history.push('/part-reports')}
        >
          <Icon type="bar-chart" />
          Parts Reports
        </Menu.Item>
      );

      if (isCardinalCouriersShop) {
        return [
          ...defaultItems,
          <Menu.Item
            key="/downtimecost"
            onClick={() => this.props.history.push('/downtimecost')}
          >
            <Icon type="dollar" />
            Downtime Cost Analytics
          </Menu.Item>,
          <Menu.Item
            key="/fleethealth"
            onClick={() => this.props.history.push('/fleethealth')}
          >
            <Icon type="heart" />
            Fleet Ranking
          </Menu.Item>,
          <Menu.Item
            key="/shopperformance"
            onClick={() => this.props.history.push('/shopperformance')}
          >
            <Icon type="line-chart" />
            Shop Performance
          </Menu.Item>,
          <Menu.Item
            key="/reports"
            onClick={() => this.props.history.push('/reports')}
          >
            <Icon type="bar-chart" />
            Technician Efficiency
          </Menu.Item>,
        ];
      }

      if (isAmerTestShop) {
        return [...defaultItems];
      }

      return defaultItems;
    };

    const fleetAnalytics = (
      <SubMenu
        key="Analytics_Fleet_Performance"
        title={
          <span>
            <Icon type="dashboard" />
            Fleet Analytics
          </span>
        }
      >
        {getFleetAnalytics()}
      </SubMenu>
    );

    const smartMaintenance = isCardinalCouriersShop ? (
      <SubMenu
        key="Smart_Maintenance_Cardinal_Courier"
        title={
          <span>
            <Icon type="radius-setting" />
            Smart Maintenance
          </span>
        }
      >
        <Menu.Item
          key="/appointments"
          onClick={() => this.props.history.push('/appointments')}
        >
          <LineChartOutlined />
          Smart PM Schedule
        </Menu.Item>
      </SubMenu>
    ) : null;

    const settings = customFleets.ufpFleet.includes(ShopStore.currentShop.id) ? (
      <SubMenu
        key="Settings"
        title={
          <span>
            <Icon type="setting" />
            Settings
          </span>
        }
      >
        <Menu.Item
          key="/contacts"
          onClick={() => this.props.history.push('/contacts')}
          data-test="menu-contacts"
        >
          <Icon type="contacts" />
          Vendor Management
        </Menu.Item>
        <Menu.Item
          key="/users"
          onClick={() => this.props.history.push('/user-management')}
          data-test="menu-users"
        >
          <Icon type="user" />
          Users & Notifications
        </Menu.Item>
        <Menu.Item
          key="/archived-assets"
          onClick={() => this.props.history.push('/archived-assets')}
          data-test="menu-archived-page"
        >
          <Icon type="history" />
          Archived Assets
        </Menu.Item>
        <Menu.Item
          key="/shop-locations"
          onClick={() => this.props.history.push('/shop-locations')}
          data-test="menu-shop-locations"
        >
          <Icon type="home" />
          Locations
        </Menu.Item>
      </SubMenu>
    ) : (
      <SubMenu
        key="Settings"
        title={
          <span>
            <Icon type="setting" />
            Settings
          </span>
        }
      >
        <Menu.Item
          key="/contacts"
          onClick={() => this.props.history.push('/contacts')}
          data-test="menu-contacts"
        >
          <Icon type="contacts" />
          Vendor Management
        </Menu.Item>
        <Menu.Item
          key="/users"
          onClick={() => this.props.history.push('/user-management')}
          data-test="menu-users"
        >
          <Icon type="user" />
          Users & Notifications
        </Menu.Item>
        <Menu.Item
          key="/archived-assets"
          onClick={() => this.props.history.push('/archived-assets')}
          data-test="menu-archived-page"
        >
          <Icon type="history" />
          Archived Assets
        </Menu.Item>
      </SubMenu>
    );

    const itemsToReturn = [
      <SubMenu
        key="Smart_Maintenance"
        title={
          <span>
            <Icon type="tool" />
            Maintenance
          </span>
        }
      >
        <Menu.Item
          key="/vehicles"
          onClick={() => this.props.history.push('/vehicles')}
          data-test="menu-assets"
        >
          <Icon type="car" />
          Assets
        </Menu.Item>
        <Menu.Item
          key="/issues"
          onClick={() => this.props.history.push('/issues')}
          data-test="menu-issues"
        >
          <Icon type="warning" />
          Issues
        </Menu.Item>
        <Menu.Item
          key="/parts"
          onClick={() => this.props.history.push('/parts')}
          data-test="menu-parts"
        >
          <span>
            <i className="fas fa-boxes" style={{ marginRight: 7 }}></i>
            Parts List
          </span>
        </Menu.Item>
        {/* WORK Orders */}
        <Menu.Item
          key="/work-order"
          onClick={() => this.props.history.push('/work-order')}
          data-test="menu-work-page"
        >
          <span>
            <Icon type="book" />
            Work Order Management
          </span>
        </Menu.Item>
        <Menu.Item
          key="/sftp-work-orders"
          onClick={() => this.props.history.push('/sftp-work-order')}
          data-test="menu-sftp-work-order"
        >
          <span>
          <Icon type="database" />
            SFTP Work Orders
          </span>
        </Menu.Item>

        {/* Purchase Order */}
        <Menu.Item
          key="/purchase-orders"
          onClick={() => this.props.history.push('/purchase-orders')}
          data-test="menu-purchase-page"
        >
          <span>
            <Icon type="snippets" />
            {ShopStore.currentShop.id === 374 ? 'Invoices' : 'Purchase Orders'}
          </span>
        </Menu.Item>
        <Menu.Item
          key="/service-interval"
          onClick={() => this.props.history.push('/service-interval')}
          data-test="menu-service-interval"
        >
          <Icon type="calendar" />
          Service Intervals
        </Menu.Item>
        <Menu.Item
          key="/warranties"
          onClick={() => this.props.history.push('/warranties')}
          data-test="menu-warranties"
        >
          <Icon type="file-protect" />
          Warranties
        </Menu.Item>
      </SubMenu>,
      fleetAnalytics,
      smartMaintenance,
      settings,
    ];

    return itemsToReturn;
  }

  get userSidebar() {
    return [
      <Menu.Item
        key={`/user/${CurrentUserStore.user.id}`}
        onClick={() =>
          this.props.history.push(`/user/${CurrentUserStore.user.id}`)
        }
      >
        <Icon type="user" />
        My Profile
      </Menu.Item>,
      <Menu.Item key="/cars" onClick={() => this.props.history.push('/cars')}>
        <Icon type="car" />
        Vehicles
      </Menu.Item>,
      <Menu.Item
        key="/appointments"
        onClick={() => this.props.history.push('/appointments')}
      >
        <Icon type="calendar" />
        Appointments
      </Menu.Item>,
      <Menu.Item
        key="/pages/chat"
        onClick={() => this.props.history.push('/pages/chat')}
      >
        <span>
          <Icon type="message" />
          Chat
        </span>
      </Menu.Item>,
    ];
  }

  get technicianSidebar() {
    return [
      <SubMenu
        key="Assets_Overview"
        title={
          <span>
            <Icon type="dashboard" />
            Assets overview
          </span>
        }
      >
        <Menu.Item
          key="/vehicles"
          onClick={() => this.props.history.push('/vehicles')}
          data-test="menu-assets"
        >
          <Icon type="car" />
          Assets
        </Menu.Item>
      </SubMenu>,
      <SubMenu
        key="Maintenance"
        title={
          <span>
            <Icon type="tool" />
            Maintenance
          </span>
        }
      >
        <Menu.Item
          key="/work-order"
          onClick={() => this.props.history.push('/work-order')}
          data-test="menu-work-page"
        >
          <span>
            <Icon type="book" />
            Work Order Management
          </span>
        </Menu.Item>
        <Menu.Item
          key="/parts"
          onClick={() => this.props.history.push('/parts')}
          data-test="menu-parts"
        >
          <span>
            <i className="fas fa-boxes" style={{ marginRight: 7 }}></i>
            Parts List
          </span>
        </Menu.Item>
      </SubMenu>,
    ];
  }

  get noAuthSidebar() {
    return [
      <Menu.Item key="/login" onClick={() => this.props.history.push('/login')}>
        Login
      </Menu.Item>,
      <Menu.Item
        key="/register"
        onClick={() => this.props.history.push('/register')}
      >
        Register
      </Menu.Item>,
    ];
  }

  getCustomSideBar = () => {
    return [
      <Menu.Item
        key="/report"
        onClick={() => this.props.history.push('/report')}
      >
        <span>
          <Icon type="bar-chart" />
          Dashboard
        </span>
      </Menu.Item>,
      <Menu.Item key="/cars" onClick={() => this.props.history.push('/cars')}>
        <Icon type="car" />
        Vehicles
      </Menu.Item>,
      <Menu.Item
        key="/user-management"
        onClick={() => this.props.history.push('/user-management')}
      >
        <span>
          <Icon type="user" />
          User Management
        </span>
      </Menu.Item>,
      <Menu.Item
        key="/usage-report"
        onClick={() => this.props.history.push('/usage-report')}
      >
        <span>
          <Icon type="fund" />
          Usage Report
        </span>
      </Menu.Item>,
    ];
  };

  render() {
    const { location, collapsed } = this.props;
    let sections = [];
    if (CurrentUserStore.loaded) {
      if (!CurrentUserStore.user) {
        sections = this.noAuthSidebar;
      } else {
        switch (CurrentUserStore.user.role) {
          case 'admin':
          case 'dealership':
            sections = this.adminDealerSidebar;
            break;
          case 'technician':
            sections = this.technicianSidebar;
            break;
          case 'user':
          default:
            sections = this.userSidebar;
        }
      }
    }
    //Temporarily shutting this down, probably remove in the future
    const isAdminAndNussbaumShop = false; //ShopStore.currentShop.id === 1065 && CurrentUserStore.user.role === 'admin';

    return (
      <>
        {this.state.width > 992 ? (
          <Sider
            width={isAdminAndNussbaumShop ? 320 : 250}
            collapsedWidth={0}
            style={{
              background: '#fff',
              position: 'fixed',
              top: '64px',
              height: '90vh',
              zIndex: 3,
              overflowY: 'auto',
              overflowX: 'clip',
            }}
            collapsed={collapsed}
          >
            <Menu
              mode="inline"
              // selectedKeys={[location.pathname]}
              selectedKeys={[AppStore.selectedSidebarKey]}
              defaultOpenKeys={[
                'Smart_Maintenance',
                'Analytics_Fleet_Performance',
                'Settings',
                'assets_overview',
              ]}
              // openKeys={this.state.submenu}
              // onOpenChange={(menu) => this.setState({ submenu: menu })}
              style={{
                height: '100%',
                borderRight: 0,
                overflowY: 'auto',
                overflowX: 'clip',
              }}
            >
              {customFleets.gmrv.includes(ShopStore.currentShop.id)
                ? this.getCustomSideBar()
                : sections}
            </Menu>
          </Sider>
        ) : (
          <>
            <Drawer closable={false} onClose={this.onClose} visible={collapsed}>
              <Menu
                mode="inline"
                // selectedKeys={[location.pathname]}
                selectedKeys={[AppStore.selectedSidebarKey]}
                defaultOpenKeys={[
                  'Smart_Maintenance',
                  'Analytics_Fleet_Performance',
                  'Settings',
                ]}
                // openKeys={this.state.submenu}
                // onOpenChange={(menu) => this.setState({ submenu: menu })}
                style={{ height: '100%', borderRight: 0 }}
              >
                {sections}
              </Menu>
            </Drawer>
          </>
        )}
      </>
    );
  }
}

decorate(Sidebar, {
  adminDealerSidebar: computed,
});

export default withRouter(observer(Sidebar));
