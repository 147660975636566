import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { decorate } from 'mobx';
import PropTypes from 'prop-types';
import _, { debounce, tail } from 'lodash';

import { ShopStore, WorkOrderStore } from 'stores';
import {
  Col,
  Row,
  Typography,
  Input,
  Form,
  Button,
  Select,
  Spin,
  Popconfirm,
  InputNumber,
  Tooltip,
  Tag,
} from 'antd';

import WorkOrderLineItemLabors from '../Labors/WOLineItemLabors';
import WorkOrderLineItemParts from '../Parts/WOLineItemParts';
import WorkOrderLineItemIssues from '../Issues/WOLineItemIssues';
import TotalAmount from 'components/TotalAmount';
import FormLegend from 'components/Forms/FormLegend';
import WorkOrderLineItemContext from './WorkOrderLineItemContext';
import FlexRow from 'components/General/FlexRow';
import FlexColum from 'components/General/FlexColum';
import IconWithBorders from 'components/Icons/IconWithBorder';
import { customFleets } from 'shared';

const { Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

class WorkOrderLineItemDetails extends Component {
  static contextType = WorkOrderLineItemContext;

  constructor(props) {
    super(props);
    this.lastFetchSystemCodeId = 0;
    this.fetchSystemCode = debounce(this.fetchSystemCode, 800);

    this.lastFetchAssemblyCodeId = 0;
    this.fetchAsmCodesForSysCode = debounce(this.fetchAsmCodesForSysCode, 800);
  }

  static propTypes = {
    childRef: PropTypes.func,
    onChangeDescription: PropTypes.func,
    onChangeField: PropTypes.func,
    onAddNewPart: PropTypes.func.isRequired,
    onUpdateLaborItem: PropTypes.func.isRequired,
    onUpdatePartItem: PropTypes.func.isRequired,
    onUpdateServiceItem: PropTypes.func.isRequired,
    onRemoveLineItem: PropTypes.func.isRequired,
    onSelectSystemCode: PropTypes.func,
    onSelectAsmCode: PropTypes.func,
    onUpdateMiscellaneousItem: PropTypes.func.isRequired,
    onUpdateDefectItem: PropTypes.func.isRequired,
  };

  state = {
    currentServiceTaskOnSearch: '',
    fetchingSystemCode: false,
    allSystemCodeOptions: [],
    currentSystemCodeOptions: [],
    fetchingAssemblyCode: false,
    systemCodeAsms: [],
    currentAssemblyCodeOptions: [],
    editingLineItemTitle: false,
  };

  shouldDisableLaborCost = false;
  shouldDisablePartsCost = false;

  laborsRef = null;
  partsRef = null;
  issuesRef = null;

  get localLineItem() {
    const { lineItem } = this.context;
    return lineItem;
  }

  get description () {
    return this.localLineItem?.description || '';
  }

  get labours() {
    return this.localLineItem?.labours || [];
  }

  get parts() {
    return this.localLineItem?.parts || [];
  }

  get services() {
    return this.localLineItem?.services || [];
  }

  get miscellaneous() {
    return this.localLineItem?.miscellaneous || [];
  }

  get lineItemDefects() {
    return this.localLineItem?.defects || [];
  }

  get assetDefects() {
    return this.context.assetDefects || [];
  }

  get availableDefectsToSelectFrom() {
    return this.assetDefects.filter(
      (defect) => !this.lineItemDefects.some(
        (d) => String(d.id_defect) === String(defect.id)
      )
    );
  }

  get loadingAssetDefects() {
    return this.context.loadingAssetDefects;
  }

  get laborTotal() {
    return _.sumBy(this.labours, (l) => l.hours * l.cost);
  }

  get partsTotal() {
    return _.sumBy(this.parts, (part) => {
      const cost = part.cost || 0;
      const markup = part.markup || 0;
      const amount = part.amount || 0;

      return (cost + (cost * markup) / 100) * amount;
    });
  }

  get miscellaneousTotal() {
    return _.sumBy(this.miscellaneous, (miscItem) => {
      return this.calculateAmount(miscItem);
    });
  }

  get lineSubtotal() {
    return (
      (this.laborTotal || 0) +
      (this.partsTotal || 0) +
      (this.miscellaneousTotal || 0)
    );
  }

  get localSystemCode() {
    const vmrsSysCode = this.localLineItem?.vmrs_sys_code;
    const vmrsSysText = this.localLineItem?.vmrs_sys_text;
    if (vmrsSysCode && vmrsSysText) {
      return `${vmrsSysCode} - ${vmrsSysText}`;
    }
    return this.localLineItem?.vmrs_sys_code_id || null;
  }

  get localAssemblyCode() {
    const vmrsAsmCode = this.localLineItem?.vmrs_asm_code;
    const vmrsAsmText = this.localLineItem?.vmrs_asm_text;
    if (vmrsAsmCode && vmrsAsmText) {
      return `${vmrsAsmCode} - ${vmrsAsmText}`;
    }
    return this.localLineItem?.vmrs_asm_code_id || null;
  }

  get vmrsSystemCodeOptions() {
    return this.state.currentSystemCodeOptions.map(
      (systemCodeOption, index) => (
        <Option key={index} value={systemCodeOption.id}>
          {`${systemCodeOption.sys_text} - ${systemCodeOption.text}`}
        </Option>
      )
    );
  }

  get vmrsAssemblyCodeOptions() {
    return this.state.currentAssemblyCodeOptions.map(
      (assemblyCodeOption, index) => (
        <Option key={index} value={assemblyCodeOption.id}>
          {`${assemblyCodeOption.asm_text} - ${assemblyCodeOption.text}`}
        </Option>
      )
    );
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);

    // if parts is empty, add a new part by default
    if (!this.parts.length) {
      this.onAddExistingPart();
    }

    this.fetchSystemCode('', true);
  }

  fetchSystemCode = async (value, searchAll = false) => {
    this.lastFetchSystemCodeId += 1;
    const fetchId = this.lastFetchSystemCodeId;
    this.setState({ fetchingSystemCode: true });
    if (!value && !searchAll) {
      this.setState({ fetchingSystemCode: false });
      return;
    }
    const { data } = await WorkOrderStore.searchVmrs({
      value,
      type: 'system_code',
    });
    if (fetchId !== this.lastFetchSystemCodeId) {
      // for fetch callback order
      return;
    }
    this.setState({
      allSystemCodeOptions: data,
      currentSystemCodeOptions: data,
      fetchingSystemCode: false,
    });
  };

  filterSystemCodes = (value) => {
    if (!value) {
      this.setState({
        currentSystemCodeOptions: this.state.allSystemCodeOptions,
      });
      return;
    }
    const { allSystemCodeOptions } = this.state;
    const filteredSystemCodes = allSystemCodeOptions.filter((systemCode) => {
      return (
        systemCode.text.toLowerCase().includes(value.toLowerCase()) ||
        systemCode.sys_text.toLowerCase().includes(value.toLowerCase())
      );
    });
    this.setState({ currentSystemCodeOptions: filteredSystemCodes });
  };

  handleChangeSystemCode = (vmrsKeyId) => {
    this.setState({ fetchingSystemCode: false }, () => {
      this.fetchAsmCodesForSysCode(vmrsKeyId);

      if (this.props.onSelectSystemCode) {
        this.props.onSelectSystemCode(vmrsKeyId);
      }
    });
  };

  fetchAsmCodesForSysCode = async (vmrsKeyId) => {
    this.lastFetchAssemblyCodeId += 1;
    const fetchId = this.lastFetchAssemblyCodeId;
    this.setState({ fetchingAssemblyCode: true });
    if (!vmrsKeyId) {
      this.setState({ fetchingAssemblyCode: false });
      return;
    }
    const { data } = await WorkOrderStore.getAsmCodesForSysCode(vmrsKeyId);
    if (fetchId !== this.lastFetchAssemblyCodeId) {
      // for fetch callback order
      return;
    }
    this.setState({
      currentAssemblyCodeOptions: data,
      systemCodeAsms: data,
      fetchingAssemblyCode: false,
    });
  };

  filterAssemblyCodes = (value) => {
    const { systemCodeAsms } = this.state;
    const filteredAsmCodes = systemCodeAsms.filter((asm) => {
      return asm.text.toLowerCase().includes(value.toLowerCase());
    });
    this.setState({ currentAssemblyCodeOptions: filteredAsmCodes });
  };

  onChangeDescription = (event) => {
    const { value } = event.target;
    if (this.props.onChangeField) {
      this.props.onChangeField(value, 'description');
    }
  };

  onAddLabor = () => {
    const { onAddLabor } = this.context;
    onAddLabor(this.localLineItem.key);
  };

  onAddExistingPart = (existingPart = {}) => {
    const { onAddPart } = this.context;
    onAddPart(this.localLineItem.key, existingPart);
  };

  onAddIssue = () => {
    const { onAddIssue } = this.context;
    onAddIssue(this.localLineItem.key);
  };

  onAddMiscellaneous = () => {
    const { onAddMiscellaneous } = this.context;
    onAddMiscellaneous(this.localLineItem.key);
  };

  onAddDefect = () => {
    const { onAddDefect } = this.context;
    onAddDefect(this.localLineItem.key);
  };

  onRemoveLabor = (event, itemKey) => {
    const { onRemoveLabor } = this.context;
    onRemoveLabor(itemKey, this.localLineItem.key);
  };

  onRemovePart = (event, itemKey) => {
    const { onRemovePart } = this.context;
    onRemovePart(itemKey, this.localLineItem.key);
  };

  onRemoveIssue = (event, itemKey) => {
    const { onRemoveIssue } = this.context;
    onRemoveIssue(itemKey, this.localLineItem.key);
  };

  onRemoveMiscellaneous = (event, itemKey) => {
    const { onRemoveMiscellaneous } = this.context;
    onRemoveMiscellaneous(itemKey, this.localLineItem.key);
  };

  onRemoveDefect = (event, itemKey) => {
    const { onRemoveDefect } = this.context;
    onRemoveDefect(itemKey, this.localLineItem.key);
  };

  onAddItemClick = (itemRef) => {
    switch (itemRef) {
      case 'Labor':
        return this.onAddLabor();
      case 'Part':
        return this.onAddExistingPart();
      case 'Issue':
        return this.onAddIssue();
      case 'Miscellaneous':
        return this.onAddMiscellaneous();
      default:
        return;
    }
  };

  onChangeField = (newVal, fieldName) => {
    this[fieldName] = newVal;

    if (this.props.onChangeField) {
      this.props.onChangeField(newVal, fieldName);
    }
  };

  onUpdatePartLineTotal = () => {
    if (this.props.onChangeField) {
      this.props.onChangeField(this.partsTotal, 'partsTotal');
    }
  };

  onSearchServiceTask = (value) => {
    this.setState({ currentServiceTaskOnSearch: value });
  };

  calculateAmount = (miscItem) => {
    const cost = miscItem.unit_cost || 0;
    const quantity = miscItem.quantity || 0;

    return cost * quantity;
  };

  formatSelectedDefect = (defect) => {
    const defectOption = this.assetDefects.find(d => d.id === defect.id_defect);
    if (!defectOption) {
      return '';
    }
    return (
      <React.Fragment>
        <Tag color={defectOption.isResolved ? 'green' : 'red'}>
          {defectOption.isResolved ? 'Resolved' : 'Unresolved'}
        </Tag>
        {defectOption.comment}
      </React.Fragment>
    )
  }

  render() {
    const {
      shopUsers,
      shopParts,
      currentCarIssues,
      isDisabled,
      screenSize,
    } = this.context;

    return (
      <Form>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <FormLegend>
              {this.state.editingLineItemTitle ? (
                <>
                  <Input
                    id={`line-item-title-${this.localLineItem.key}`}
                    value={this.localLineItem.title}
                    onChange={(e) =>
                      this.onChangeField(e.target.value, 'title')
                    }
                  />
                  <Tooltip title="Save">
                    <Button
                      icon="save"
                      type="link"
                      onClick={() => {
                        this.setState({ editingLineItemTitle: false });
                      }}
                    />
                  </Tooltip>
                </>
              ) : (
                <>
                  <Text strong>{this.localLineItem.title}</Text>
                  <Tooltip title="Edit Name">
                    <Button
                      icon="edit"
                      type="link"
                      onClick={() => {
                        this.setState({ editingLineItemTitle: true });
                      }}
                    />
                  </Tooltip>
                </>
              )}
              <Popconfirm
                placement="top"
                title={'Are you sure?'}
                onConfirm={() => this.props.onRemoveLineItem()}
                okText="Yes"
                cancelText="No"
              >
                <Button icon="delete" type="link" className="delete-danger" />
              </Popconfirm>
            </FormLegend>
          </Col>
          <Col xs={24} sm={12}>
            <Text strong>VMRs System Code:</Text>
            <Select
              value={this.localSystemCode}
              placeholder="Search"
              notFoundContent={
                this.state.fetchingSystemCode ? <Spin size="small" /> : null
              }
              filterOption={false}
              onSearch={this.filterSystemCodes}
              onChange={this.handleChangeSystemCode}
              showSearch
              disabled={isDisabled}
            >
              {this.vmrsSystemCodeOptions}
            </Select>
          </Col>
          <Col xs={24} sm={12}>
            <Text strong>VMRs Assembly Code:</Text>
            <Select
              value={this.localAssemblyCode}
              onChange={(value) => {
                if (this.props.onSelectAsmCode) {
                  this.props.onSelectAsmCode(value);
                }
              }}
              placeholder="Search"
              notFoundContent={
                this.state.fetchingAssemblyCode ? <Spin size="small" /> : null
              }
              filterOption={false}
              onSearch={this.filterAssemblyCodes}
              showSearch
              disabled={isDisabled}
            >
              {this.vmrsAssemblyCodeOptions}
            </Select>
          </Col>
          <Col xs={8} sm={6}>
            <Text strong>Labor Cost:</Text>
            <TotalAmount amount={this.laborTotal} />
          </Col>
          <Col xs={8} sm={6}>
            <Text strong>Parts Cost:</Text>
            <TotalAmount amount={this.partsTotal} />
          </Col>
          <Col xs={8} sm={6}>
            <Text strong>Other (Misc) Cost:</Text>
            <TotalAmount amount={this.miscellaneousTotal} />
          </Col>
          <Col xs={8} sm={6}>
            <Text strong>Subtotal:</Text>
            <TotalAmount amount={this.lineSubtotal} />
          </Col>
          <Col span={24}>
            <Text strong>Description:</Text>
            <TextArea
              rows={4}
              placeholder="Add Description"
              value={this.description}
              onChange={this.onChangeDescription}
              disabled={isDisabled}
            />
          </Col>
          <Col span={24}>
            <Button
              type="link"
              onClick={() => this.onAddIssue()}
              icon="plus-circle"
            >
              Link Existing Issues
            </Button>
            <Button
              type="link"
              onClick={() => this.onAddLabor()}
              icon="plus-circle"
              style={{ marginRight: '10px' }}
            >
              Add Labor/Vendor
            </Button>
            <Button
              type="link"
              onClick={() => this.onAddExistingPart()}
              icon="plus-circle"
              style={{ marginRight: '10px' }}
            >
              Add Existing Part
            </Button>
            <Button
              type="link"
              onClick={() => {
                if (this.props.onAddNewPart) {
                  this.props.onAddNewPart();
                }
              }}
              icon="plus-circle"
              style={{ marginRight: '10px' }}
            >
              Add New Part
            </Button>
            <Button
              type="link"
              onClick={() => this.onAddDefect()}
              icon="plus-circle"
            >
              Link Existing Defect
            </Button>
            <Button
              type="link"
              onClick={() => this.onAddMiscellaneous()}
              icon="plus-circle"
            >
              Add Misc
            </Button>
          </Col>
          <Col span={24}>
            <WorkOrderLineItemLabors
              title="Labors (Technician) / Vendors"
              description="Labor/Vendor"
              items={this.labours}
              shopUsers={shopUsers}
              childRef={(ref) => (this.laborsRef = ref)}
              onAddItem={() => this.onAddItemClick('Labor')}
              onRemoveItem={this.onRemoveLabor}
              // onUpdateLineTotal={this.onUpdateLaborLineTotal}
              onUpdateLocalItem={this.props.onUpdateLaborItem}
              isDisabled={isDisabled}
            />
          </Col>
          <Col span={24}>
            <WorkOrderLineItemParts
              title="Parts"
              description="Parts"
              items={this.parts}
              shopParts={shopParts}
              childRef={(ref) => (this.partsRef = ref)}
              onAddItem={() => this.onAddItemClick('Part')}
              onRemoveItem={this.onRemovePart}
              // onUpdateLineTotal={this.onUpdatePartLineTotal}
              onAddNewPart={this.props.onAddNewPart}
              onUpdateLocalItem={this.props.onUpdatePartItem}
              isDisabled={isDisabled}
            />
          </Col>
          {!isDisabled && (
            <Col span={24}>
              <WorkOrderLineItemIssues
                title="Link issues"
                description="link issues"
                items={this.services}
                currentCarIssues={currentCarIssues}
                childRef={(ref) => (this.issuesRef = ref)}
                onAddItem={() => this.onAddItemClick('Issue')}
                onRemoveItem={this.onRemoveIssue}
                onUpdateLocalItem={this.props.onUpdateServiceItem}
              />
            </Col>
          )}
          {/* Miscellaneous */}
          <Col span={24}>
            <Row gutter={[8, 8]}>
              <Col span={24}>
                {this.miscellaneous.map((miscItem, index) => {
                  return (
                    <Row
                      key={miscItem.key}
                      style={{ margin: 0 }}
                      gutter={[8, 8]}
                    >
                      <Col xs={24} sm={13}>
                        <FlexRow>
                          {index === 0 ? (
                            <FlexColum
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                marginRight: 10,
                              }}
                            >
                              <IconWithBorders icon="setting" />
                            </FlexColum>
                          ) : (
                            <FlexColum
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                marginRight: screenSize <= 575 ? 0 : 10,
                              }}
                            >
                              <div
                                style={{
                                  width: screenSize <= 575 ? 0 : 35,
                                }}
                              />
                            </FlexColum>
                          )}
                          <FlexColum style={{ flex: 1 }}>
                            <Text strong>Other (Misc)</Text>
                            <Input
                              value={miscItem.description}
                              onChange={(event) => {
                                this.props.onUpdateMiscellaneousItem(
                                  miscItem.key,
                                  event.target.value,
                                  'description'
                                );
                              }}
                              disabled={isDisabled}
                            />
                          </FlexColum>
                        </FlexRow>
                      </Col>
                      <Col xs={6} sm={3}>
                        <Text strong>Quantity:</Text>
                        <InputNumber
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                          style={{ width: '100%' }}
                          value={miscItem.quantity}
                          onChange={(value) => {
                            this.props.onUpdateMiscellaneousItem(
                              miscItem.key,
                              value,
                              'quantity'
                            );
                          }}
                          disabled={isDisabled}
                          inputMode="tel"
                          onFocus={() => {}}
                          onBlur={() => {}}
                        />
                      </Col>
                      <Col xs={6} sm={3}>
                        <Text strong>Unit Cost:</Text>
                        <InputNumber
                          formatter={(value) =>
                            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                          style={{ width: '100%' }}
                          value={miscItem.unit_cost}
                          onChange={(value) => {
                            this.props.onUpdateMiscellaneousItem(
                              miscItem.key,
                              value,
                              'unit_cost'
                            );
                          }}
                          disabled={isDisabled}
                          inputMode="tel"
                          onFocus={() => {}}
                          onBlur={() => {}}
                        />
                      </Col>
                      <Col xs={6} sm={5}>
                        <FlexRow>
                          <FlexColum style={{ flex: 1 }}>
                            <Text strong>Amount:</Text>
                            <TotalAmount
                              amount={this.calculateAmount(miscItem)}
                            />
                          </FlexColum>
                          <FlexColum
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Button
                              type="link"
                              icon="delete"
                              className="delete-danger"
                              onClick={(event) => {
                                event.stopPropagation();
                                this.onRemoveMiscellaneous(event, miscItem.key);
                              }}
                            />
                          </FlexColum>
                        </FlexRow>
                      </Col>
                      {index === this.miscellaneous.length - 1 && (
                        <Col span={24}>
                          <Button
                            style={{
                              display: 'block',
                              marginLeft: screenSize <= 575 ? 0 : 35,
                            }}
                            icon="plus-circle"
                            type="link"
                            onClick={this.onAddMiscellaneous}
                          >
                            Add Misc
                          </Button>
                        </Col>
                      )}
                    </Row>
                  );
                })}
              </Col>
            </Row>
          </Col>

          {/* check if UFP fleet */}
          {customFleets.ufpFleet.includes(ShopStore.currentShop.id) && (
            // Show defects line items
            <Col span={24}>
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  {this.lineItemDefects.map((defect, index) => {
                    return (
                      <Row
                        key={defect.key}
                        style={{ margin: 0 }}
                        gutter={[8, 8]}
                      >
                        <Col xs={24} sm={24}>
                          <FlexRow>
                            {index === 0 ? (
                              <FlexColum
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'flex-end',
                                  marginRight: 10,
                                }}
                              >
                                <IconWithBorders icon="info-circle" />
                              </FlexColum>
                            ) : (
                              <FlexColum
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'flex-end',
                                  marginRight: screenSize <= 575 ? 0 : 10,
                                }}
                              >
                                <div
                                  style={{
                                    width: screenSize <= 575 ? 0 : 35,
                                  }}
                                />
                              </FlexColum>
                            )}
                            <FlexColum style={{ flex: 1 }}>
                              <Text strong>Select Defect:</Text>
                              <Select
                                value={this.formatSelectedDefect(defect)}
                                onChange={(value) => {
                                  this.props.onUpdateDefectItem(
                                    defect.key,
                                    value,
                                    'id_defect'
                                  );
                                }}
                                disabled={this.loadingAssetDefects}
                                showSearch
                                optionFilterProp="children"
                                filterOption={
                                  // get the current assetDefects and search its comments for the given string
                                  (inputValue, option) => {
                                    const defectComment = tail(option.props.children || []).join(' ');
                                    return defectComment
                                      .toLowerCase()
                                      .indexOf(inputValue.toLowerCase()) >= 0
                                  }
                                }
                              >
                                {
                                  this.loadingAssetDefects ? (
                                    <Select.Option value={null}>
                                      <Spin size="small" />
                                    </Select.Option>
                                  ) : (
                                    this.availableDefectsToSelectFrom.map((defectOption) => (
                                      <Select.Option
                                        key={defectOption.id}
                                        value={defectOption.id}
                                      >
                                        <Tag color={defectOption.isResolved ? 'green' : 'red'}>
                                          {defectOption.isResolved ? 'Resolved' : 'Unresolved'}
                                        </Tag>
                                        {defectOption.comment}
                                      </Select.Option>
                                    ))
                                  )
                                }
                              </Select>
                            </FlexColum>
                            <FlexColum
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <Button
                                type="link"
                                icon="delete"
                                className="delete-danger"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  this.onRemoveDefect(event, defect.key);
                                }}
                              />
                            </FlexColum>
                          </FlexRow>
                        </Col>
                        {index === this.lineItemDefects.length - 1 && (
                          <Col span={24}>
                            <Button
                              style={{
                                display: 'block',
                                marginLeft: screenSize <= 575 ? 0 : 35,
                              }}
                              icon="plus-circle"
                              type="link"
                              onClick={this.onAddDefect}
                            >
                              Add Defect
                            </Button>
                          </Col>
                        )}
                      </Row>
                    );
                  })}
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Form>
    );
  }
}

decorate(WorkOrderLineItemDetails, {});

export default observer(WorkOrderLineItemDetails);
