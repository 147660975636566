import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Form } from 'antd';
import { observe, toJS } from 'mobx';
import { notification } from 'antd';
import moment from 'moment-timezone';
import _ from 'lodash';

import styled from 'styled-components';

import { AppStore, ShopStore, UserStore, CurrentUserStore } from 'stores';

import { StyledPitstopModal } from './common/styled';
import SelectPriorities from './common/SelectPriorities';
import FormMultiSelect from './common/FormMultiSelect';
import FormSelect from './common/FormSelect';

const StyledP = styled.div`
  font-size: 14px;
  margin-bottom: 20px;
`;

class AddVehicleIssueAlertModal extends React.Component {
  state = {
    shopUsers: [],
  };

  componentDidMount () {
    this.init();
  }

  init = async () => {
    this.getShopUsers();
  };
  getShopUsers = async () => {
    const shopUsers = await UserStore.fetchShopUsers(ShopStore.currentShop.id);

    const users = shopUsers
      .filter((user) => user.firstName || user.lastName)
      .map((user) => {
        return {
          name: `${user.firstName} ${user.lastName}`,
          id: user.userId,
        };
      });

    await this.setState({ users: users || [], shopUsers });
  };

  shopChangeWatcher = observe(ShopStore.currentShop, 'id', () => {
    if (ShopStore.isAdmin()) return;
    return this.init();
  });

  componentWillUnmount () {
    this.shopChangeWatcher();
  }

  generatePriorityAlerts = (priorities) => {
    return ShopStore.generatePriorityNumbersFromPriorityList(priorities);
  };

  onOkHandler = () => {
    const { form } = this.props;
    try {
      this.props.form.validateFields(async (err, values) => {
        if (err) {
          notification.error({
            message: 'Error',
            description: 'Please fill in all required fields',
          });
        } else {
          let timezone = CurrentUserStore.user.settings.timezone;

          const prioritiesAlerts = this.generatePriorityAlerts(
            values.priorities
          ).sort();

          if (!timezone) {
            timezone = 'America/Toronto';
          }

          const shopNotifications = toJS(
            ShopStore.shopNotifications.get(ShopStore.currentShop.id)
          );

          const priorityAlertsNotifications = _.chain(shopNotifications)
            .get('data.emailConfiguration')
            .filter((a) => a.email_report_type === 'PriorityAlerts')
            .value();

          for (const id of values.users) {
            const userNotifications = priorityAlertsNotifications.filter(
              (a) => a.id_user === id
            );

            let user = this.state.shopUsers.find((u) => u.userId === id);
            user = {
              id: user.userId,
              ...user,
            };

            if (_.isEmpty(userNotifications)) {
              await ShopStore.checkAndRemoveEmailBasedNotificationsForUser(
                priorityAlertsNotifications,
                user
              );

              await ShopStore.sendEmailPriorityAlertsConfig(
                ShopStore.currentShop.id,
                [user],
                moment(),
                timezone,
                prioritiesAlerts
              );
            } else {
              await ShopStore.updateEmailPriorityAlertsConfig(
                userNotifications[0].id,
                ShopStore.currentShop.id,
                [user],
                prioritiesAlerts
              );
            }
          }

          notification.success({
            message: 'Vehicle Issue Alerts Added',
            description: 'Continue adding alerts or close the modal',
          });

          AppStore.openModals.set(this.props.id, false);
          await ShopStore.getShopNotifications(ShopStore.currentShop.id, true);
        }
      });
    } catch (err) {
      AppStore.addError(`Error in updating priority alerts: ${err.message}`);
    }
  };
  render () {
    const { form } = this.props;
    const { getFieldDecorator } = form;

    return (
      <StyledPitstopModal
        id={this.props.id}
        title="Add Vehicle Issue Alert"
        width={320}
        okText="Add Alerts"
        closable
        onOk={this.onOkHandler}
        isManualClose
        cancelButtonProps={{
          type: 'danger',
          ghost: true,
          style: {
            backgroundColor: 'transparent',
            border: 'none',
            color: '#ff4d4f',
            boxShadow: 'none',
          },
        }}
      >
        <StyledP>
          An alert email will be sent whenever an issue with the selected
          priority occurs.
        </StyledP>

        <Form layout="vertical">
          <FormSelect
            label="Select User(s)"
            mode="multiple"
            decorator={getFieldDecorator}
            fieldName="users"
            options={this.state.users}
            validationRules={[
              { required: true, message: 'Please select User(s)' },
            ]}
          />
          <FormMultiSelect
            label="Priority"
            decorator={getFieldDecorator}
            fieldName="priorities"
            validationRules={[
              { required: true, message: 'Please select a priority' },
            ]}
            component={<SelectPriorities />}
          />
        </Form>
      </StyledPitstopModal>
    );
  }
}

export default observer(
  Form.create({
    name: 'add_vehicle_issue_alert',
  })(AddVehicleIssueAlertModal)
);
